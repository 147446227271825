import React, {/*  useContext, useEffect, useRef, */ useState } from 'react';
import { Row, Col, /* Container, Form, Button, Tab, Nav, Image  */ } from 'react-bootstrap';

import useTranslations from "../../lib/useTranslations";

import QuickBite from './QuickBite';

const CategoryView = (props) => {

  const { addItemToCart, business, categories, category, hideZero, optionsFirst,
    removeItem,
    setItemQuantity,
    selectedMenu,
    setSelectedCategory } = props;

  //const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  const toggleCategoryVisible = () => {
    if (category) {
      //console.log("set category to null")
      setSelectedCategory(null)
    } else {
      setSelectedCategory(category)
    }
    //setCollapsed(!isOpen)
  }

  const _productIsOffer = (product) => {
    // if the product id is in an offer in the location
    if (selectedMenu.Location == null || selectedMenu.Location.Campaigns == null) {
      return false
    }
    let found = selectedMenu.Location.Campaigns.find((offer) => offer.productId == product.id)

    return found
  }

  const isVisible = (p) => {

    if (hideZero && p.managed === true && p.quantity <= 0) {
      // product inventory is managed
      return false
    }

    return p.available === true && p.restricted != true
  }

  const titleElement = () => {
    if (category.button != "") {
      console.log("titleElement return button")
      return (
        <h3 className="mt-1 col-md-12 open-category-button centered mx-2" onClick={() => toggleCategoryVisible()}>
          <div className="space-evenly" >

            <span className="category-expander" onClick={() => toggleCategoryVisible()}  >
              <i className="fa fa-chevron-left" aria-hidden="true"  ></i>
            </span>

            <img src={category.button} />
          </div>

        </h3>
      )

    }

    return (
      <h5 className="mt-1 col-md-12 category-header">
        <div >

          <span className="category-expander" onClick={() => toggleCategoryVisible()} style={{
            marginRight: 40
          }}>
            <i className="fa fa-chevron-left fa-2x" aria-hidden="true" style={{ color: '#999' }}></i>
          </span>

          <span>
            {category.name} <br />
            {category.description}
          </span>
        </div>

      </h5>
    )
  }

  //console.log("category", category.name, category)

  return (<Row id={`category_${category.name}`} className="px-1" >

    {titleElement()}
    <Col md={12} style={{
      padding: 4
    }}>

      <div id={`category_content_${category.id}`}
        className="category-content rounded border shadow-sm mb-1">
        {category.Products.filter((p) => isVisible(p)).map((product, i) => {
          let isOffer = _productIsOffer(product)

          return (<QuickBite
            key={i}
            product={product}
            categories={categories}
            id={product.id}
            /* itemClass="menu-list" */
            image={product.photo}
            title={product.name}
            price={product.price}
            hasOrdering={true}
            priceUnit='$'
            isOffer={isOffer}
            getValue={(args) => {
              //console.log("getValue add to cart", product.id, args);
              if (args.quantity === 1) {
                // make a copy of the object
                let newItem = Object.assign({}, product);
                newItem.quantity = 1
                newItem.menu_id = product.menu_id || selectedMenu.id
                addItemToCart(newItem, args);
              } else if (args.quantity === 0) {
                removeItem(product, args);
              } else {
                setItemQuantity(product, args.quantity);
              }

            }}
            detailColour={'#F1AD3E'}
            optionsFirst={optionsFirst}
          />)
        })}

      </div>

    </Col>

  </Row>)
}

export default CategoryView