import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom';

//import PropTypes, { object } from 'prop-types'

//import { EventBus } from 'jslib/eventBus'
import { getFromApi } from '../lib/api';
import { round } from '../lib/utils';
import useTranslations from "../lib/useTranslations";
import { CartContext } from '../lib/CartContext';

import { StandardStepper, TakeawayStepper, PaymentStepper, ChangedStepper, CostsStepper, RejectedStepper } from './order_status'

export default function OrderProgress(props) {
  const { menu, setDeliveryPrice } = props
  const [order, setOrder] = useState(props.order)
  const [timer, setTimer] = useState(null)
  /* eslint-disable no-unused-vars */
  const [message, setMessage] = useState(null)

  // display the location of the delivery
  const [delivery, setDelivery] = useState(null)
  const [driver, setDriver] = useState(null)
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)


  const { OrderStatus, clearOrder, MessageSubject } = useContext(CartContext);
  const { tr, trmap /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  const timerRef = React.useRef(null)
  timerRef.current = timer

  const orderRef = React.useRef(null)
  orderRef.current = order

  const deliveryRef = React.useRef(null)
  deliveryRef.current = delivery

  const driverRef = React.useRef(null)
  driverRef.current = driver

  const history = useHistory();

  /* eslint-disable react-hooks/exhaustive-deps */
  var dataListener

  function listenForData(socket) {
    //  get acknowledgements - order received, order status etc.

    socket.addEventListener("message", dataListener = (e) => {

      let data = JSON.parse(e.data);

      if (data.Payload) {
        data = JSON.parse(data.Payload)
      }

      if (data.event === "message") {

        //console.log('message recvd', data)

        //let msg = JSON.parse(data.payload)

        //console.log('payload recvd', msg)

        handleMessage(data)
      }

    })
  }

  function onSocketOpen(socket, username) {
    // subscribe to username topic
    var msg =
    {
      "event": "subscribe",
      "channel": username,
      "payload": "subscribe"
    }
    socket.send(JSON.stringify(msg))
  }

  function connectToChatServer(username, callback) {

    let url = "wss://" + window.location.host + `/chat/orders/${username}`
    if (window.location.host.indexOf("localhost") > -1) {
      url = `ws://localhost:3008/chat/orders/${username}`
    }

    //console.log("connecting to chat server", url)

    window.orderSocket = new WebSocket(url);

    listenForData(window.orderSocket)

    let socket = window.orderSocket

    socket.onopen = function (e) {
      //console.log("[open] Connection established as", username);
      //console.log("Sending to server", username);
      onSocketOpen(socket, username)

      callback(socket)
    };

    /* socket.onmessage = function (event) {
      //console.log(`[message] Data received from server: ${event.data}`);
    }; */

    socket.onclose = function (event) {
      if (event.wasClean) {
        //console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        //alert('Se perdió la conexión con el servidor, vuelva a cargar la ventana del navegador');

        //console.log(`[close] Connection closed code=${event.code} reason=${event.reason}`);
        // don't try to reconnect, the next submission will connect
        if (window.orderSocket !== undefined) {
          try {
            window.orderSocket.close()
          } catch { }
        }
        window.orderSocket = undefined

      }
    };

    socket.onerror = function (error) {
      //alert(`[error] ${error.message} - vuelva a cargar la ventana del navegador`);
      // close the socket
      if (window.orderSocket !== undefined) {
        try {
          window.orderSocket.close()
        } catch { }
      }
      window.orderSocket = undefined
      //console.log(`[ERROR] Connection closed reason=${error.message}`);
    };

    // establish an interval timer to send a ping
    let timer = setInterval(() => {
      var msg = {
        "event": "ping",
        "channel": "status",
        "payload": "ping"
      };
      //console.log("sending ping", msg)
      socket.send(JSON.stringify(msg))

      // check for updates from the API server
      _checkOrderUpdates()
    }, 15000)

    setTimer(timer)
  }

  function _checkOrderUpdates() {
    //console.log('_checkOrderUpdates', order)

    if (order.status == "delivered") return;

    getFromApi("/api/v1/order/" + order.id, {}, (response) => {
      let order = orderRef.current
      let res = response.result

      //console.log('_checkOrderUpdates order', res, order)

      if (res.status !== order.status || res.tstamp != order.tstamp) {
        // the order has changed
        //console.log("order changed", res.status, order.status)

        if (res.status === OrderStatus.DeliveryCost) {
          _playAudio('DeliveryCost')
        } else if (response.result.status === OrderStatus.ConfirmCosts) {
          _playAudio('ExtraCosts')
        }

        res.menuId = menu.id  // ensure the order has the menu ID so we can detect when the menu changes
        // save the changed order to local storage
        setOrder(res)
      }

      // check the delivery and driver and download if we don't have them
      if (!deliveryRef.current && res.deliveryId > 0) {
        getFromApi(`/api/v1/delivery/${res.deliveryId}`, {}, (response) => {
          setDelivery(response.result)
          deliveryRef.current = response.result

          if (!driverRef.current && res.driverId > 0) {

            if (response.result.driver && response.result.driver.id > 0) {
              setDriver(response.result.driver)
              driverRef.current = response.result.driver
            } else {
              getFromApi(`/api/v1/driver/${res.driverId}`, {}, (response) => {
                setDriver(response.result)
                driverRef.current = response.result
              },
                (error) => {
                  console.log("can't get driver", error.responseText)
                })
            }
          }

        },
          (error) => {
            console.log("can't get delivery", error.responseText)
          })
      } else if (!driverRef.current && res.driverId > 0) {
        getFromApi(`/api/v1/driver/${res.driverId}`, {}, (response) => {
          setDriver(response.result)
          driverRef.current = response.result
        },
          (error) => {
            console.log("can't get driver", error.responseText)
          })
      }

    },
      (error) => {
        console.log("can't get order", error.responseText)
      })

  }

  function _establishConnection(guestId, callback) {
    if (window.orderSocket !== undefined) {
      callback(window.orderSocket)
    } else {
      //console.log("establishing connection", guestId)
      connectToChatServer(guestId, callback)
    }
  }

  useEffect(() => {

    if (props.order) {
      setOrder(props.order)

      /* eslint-disable react-hooks/exhaustive-deps */
      _establishConnection(props.order.uuid, socket => {
        //console.log("connection established")
      })
    }


    return () => {
      if (timerRef.current !== null) {
        console.log("clear interval")
        clearInterval(timerRef.current)
        setTimer(null)
      } else {
        console.log("no interval to clear")
      }
      if (window.orderSocket !== undefined) {
        window.orderSocket.removeEventListener("message", dataListener)
        window.orderSocket.close()
        window.orderSocket = undefined
      }
    }
  }, [])


  function _playAudio(eventType) {

    let url = null

    //console.log("play audio", eventType)

    if (eventType === "DeliveryCost" || eventType === "ExtraCosts") {
      url = "https://www.zono.cc/assets/audio/menuatt.mpeg"
    } else if (eventType === "orderWaitingAudio") {
      url = "https://www.zono.cc/assets/audio/orderwaiting.mp3"
    } else {
      return
    }

    //console.log("play audio from url", url)

    // temp solution play the default url
    var player = document.getElementById("orders_audio_player")
    if (player && player.canPlayType("audio/mpeg")) {
      player.setAttribute("src", url)
      player.play()
    } else {
      console.log("no audio player")
      return
    }

    /* 
        if (0 > 1) {
          // get the configured URL for the eventType (orderReceived, orderWaiting, orderConfirmed, 
          // prepareOrder, orderReady)
          if (this.config == undefined) {
            console.log("no config object")
            return
          }
    
          let url = this.config.stringValue(eventType)
          let isEnabled = this.config.boolValue("playAudio")
    
          if (url && isEnabled) {
            // play the audio
            var player = document.getElementById("orders_audio_player")
            if (player && player.canPlayType("audio/mpeg")) {
              player.setAttribute("src", url)
              player.play()
            }
          }
        } */
  }


  function _orderFromPayload(payload) {
    if (typeof payload.content === 'string') {
      return JSON.parse(payload.content)
    }
    return payload.content
  }

  function handleMessage(msg) {

    //console.log('ProductOrder orderMessage recvd', msg)
    let payload = JSON.parse(msg.payload)
    let po

    try {
      po = _orderFromPayload(payload)

      if (po && po.id === order.id) {
        order.menuId = menu.id  // ensure the order has the menu ID so we can detect when the menu changes

        setOrder(po)
        orderRef.current = po
      }
    } catch(ex) {
      // no order in the payload
    }

    let order = orderRef.current

    switch (msg.subject) {
      case MessageSubject.DeliveryCost:

        //console.log('MenuCart DeliveryCost')
        order.status = OrderStatus.DeliveryCost

        order.shippingcost = 0
        //setShippingCost(0)
        setMessage(payload.message)

        _playAudio('DeliveryCost')

        break;

      case MessageSubject.DeliveryAccepted:
        //console.log("delivery accepted", payload)
        setDelivery(payload)
        setDeliveryPrice(payload.price)

        break;

      case MessageSubject.DriverLocation:
        console.log("delivery/driver location", payload)
        setLatitude(payload.latitude)
        setLongitude(payload.longitude)

        if (driver) {
          driver.latitude = payload.latitude
          driver.longitude = payload.longitude
        }

        break;

      case MessageSubject.OrderReceived:
        //console.log('Order received');
        order.status = OrderStatus.Received
        setMessage(payload.message)

        break;
      case MessageSubject.OrderAccepted:
        //console.log('Order accepted');
        order.status = OrderStatus.Preparing
        setMessage(payload.message)

        break;
      case MessageSubject.OrderRejected:
        //console.log('Order rejected');
        order.status = OrderStatus.Rejected
        setMessage(payload.message)
        localStorage.removeItem('order')

        break;
      case MessageSubject.OrderChange:
        //console.log('Order change required');
        order.status = OrderStatus.Change
        setMessage(payload.message)
        //EventBus.emit('orderChangeRequired')
        break;
      case MessageSubject.OrderChanged:

        //console.log('Order changed');
        order.status = OrderStatus.Changed
        setMessage(payload.message)

        /* vm.setState({
          packingCost: order.packingcost,
          shippingCost: order.shippingcost,
          otherCost: order.otherCost,
          otherReason: order.otherReason,
          newTotalCost: order.totalcost,
          confirmChangeRequired: true,
          message: payload.message,
        }) */
        break;
      case MessageSubject.ExtraCosts:
        // decode the content and update the additional costs
        let newCosts

        if (typeof payload.content === 'string') {
          newCosts = JSON.parse(payload.content)
        } else {
          newCosts = payload.content
        }

        //console.log('ProductOrder additional costs', newCosts)

        order.shippingcost = newCosts.shippingcost
        order.totalcost = newCosts.newTotalCost
        order.status = payload.status
        setMessage(payload.message)

        _playAudio('ExtraCosts')

        break;
      case MessageSubject.ConfirmRequired:
        //console.log('Order changed');
        order.status = OrderStatus.ConfirmRequired
        setMessage(payload.message)
        break;
      case MessageSubject.OrderPayment:
        //console.log('Order payment required');
        order.status = OrderStatus.PaymentRequired
        setMessage(payload.message)
        break;
      case MessageSubject.OrderPaid:
        //console.log('Order payment received');
        order.status = OrderStatus.Paid
        setMessage(payload.message)

        break;
      case MessageSubject.OrderReady:
        //console.log('Order ready for dispatch');
        order.status = OrderStatus.Ready
        setMessage(payload.message)

        break;
      case MessageSubject.OrderDispatched:
        //console.log('Order dispatched');
        order.status = OrderStatus.Dispatched
        setMessage(payload.message)
        localStorage.removeItem('order')
        break;
      case MessageSubject.PaymentLink:
        //console.log('Order payment link');
        setMessage(payload.message)
        //setLink(payload.content)

        break;
      case MessageSubject.PaymentQRcode:
        setMessage(payload.message)
        //setLink(payload.content)
        break;
      default:
        // noop
        break;
    }
  }

  function _hasPaymentStatus() {

    let orderStatus = order.status

    if (orderStatus === OrderStatus.PaymentRequired ||
      orderStatus === OrderStatus.Paid
    ) {
      return true
    }
    return false
  }

  function _hasConfirmCosts() {
    let orderStatus = order.status

    if (orderStatus === OrderStatus.ConfirmCosts ||
      orderStatus === OrderStatus.DeliveryCost) {
      console.log("confirm order or delivery costs", order)
      return true
    }
    return false
  }

  function _hasConfirmStatus() {
    let orderStatus = order.status

    if (orderStatus === OrderStatus.ConfirmRequired ||
      orderStatus === OrderStatus.Change ||
      orderStatus === OrderStatus.Changed ||
      orderStatus === OrderStatus.Confirmed
    ) {
      return true
    }
    return false
  }

  function _confirmOrder() {

    // order = JSON.parse(order)
    //EventBus.emit("reconfirmOrder", order)
  }

  function _clearOrder() {
    localStorage.removeItem('pendingOrder')
    //localStorage.removeItem('deliverLocation')
    //localStorage.removeItem('deliverType')
    //localStorage.removeItem('tableNum')
    localStorage.removeItem('paymentMethod')
    const location = {
      pathname: '/detail',
      state: { selectedMenu: menu }
    }

    history.replace(location)
  }

  const _resubmit = (order) => {

    getFromApi(`/api/v1/order/resubmit/${order.id}`, {}, (result) => {
      alert(tr("Your order has been resubmitted"))
      console.log("resubmit result", result)

      setMessage(tr("Your order has been resubmitted"))
      setOrder(result.result)
    }, (error) => {
      console.log(error)
      alert(error.responseText)
    })

  }


  const ignoredOrder = (order) => {

    if (!order) {
      return <></>
    }
    //console.log("order ", order)
    let now = Date.now()

    let pd = Date.parse(order.tstamp)

    // if now > pd + 5 minutes display a warning
    if (order.status == 'auto_rejected') {

      return <div className="row" style={{
        padding: 20
      }}>
        <div className="col">
          <div>
            {trmap("The merchant has not responded to your order within {TIME} minutes", {
              TIME: 15
            })}
          </div>

          <div>
            {tr("Your order has been automatically rejected")}
          </div>

          <div>
            {trmap("Please call the merchant on {NUMBER}",
              {
                NUMBER: order.Location.phone
              })}
          </div>

          <div>
            {tr("to check if they can process your order and resubmit")}
          </div>

          <div className="cart_control-button-row">
            <div className="new-order-button" onClick={() => _resubmit(order)} >
              {tr("Resubmit order")}
            </div>
          </div>


        </div>
      </div>
    }

    if (order.status == 'new' && now > pd + (1000 * 60 * 5)) {

      return <div className="row" style={{
        padding: 20
      }}>
        <div className="col">
          <div>
            {trmap("The merchant has not responded to your order within {TIME} minutes", {
              TIME: 5
            })}
          </div>
          <div>
            {trmap("Your order will be automatically rejected after {TIME} minutes", {
              TIME: 15
            })}
          </div>
          <div>
            {trmap("Please call the merchant on {NUMBER}",
              {
                NUMBER: order.Location.phone
              })} {tr("to check the status of your order")}
          </div>
        </div>
      </div>

    }

    return <></>
  }

  const _newOrderButton = (order) => {

    if (order.status === OrderStatus.Dispatched ||
      order.status === OrderStatus.Delivered ||
      order.status === OrderStatus.Rejected || order.status == OrderStatus.AutoRejected ||
      order.status === OrderStatus.Paid
    ) {
      return <div className="cart_control-button-row">
        <div className="new-order-button" onClick={_clearOrder} >
          Nuevo Pedido
        </div>
      </div>
    }

    return <></>
  }

  const _deliveryMap = (delivery, order) => {

    let driver = driverRef.current

    if (delivery &&
      order.deliverType === 'delivery' &&
      order.status === OrderStatus.Dispatched
    ) {
      let dlat = driver ? driver.latitude : delivery.pickup.latitude
      let dlng = driver ? driver.longitude : delivery.pickup.longitude
      let lat = latitude || dlat
      let lng = longitude || dlng

      //console.log("delivery map lat/lng", lat, lng, latitude, longitude, dlat, dlng)

      return <DeliveryMap latitude={lat} longitude={lng}
        delivery={delivery}
        driver={driver || {}}
        title={tr("Delivery driver location")} onMapClick={(position) => {
          console.log("map clicked at ", position)
        }} />

    }
    return <></>
  }

  let torder = orderRef.current

  //console.log("order", order)

  if (torder.status === OrderStatus.Rejected || torder.status == OrderStatus.AutoRejected) {
    // the order has been rejected, show the rejected stepper
    return (
      <div className="menu menu-cart">
        <div className="row cart-body">
          <div className="col-12 rejected_stepper">
            <RejectedStepper order={torder} />
          </div>
        </div>

        {ignoredOrder(torder)}

        {_newOrderButton(torder)}

      </div>
    )
  } else if (_hasConfirmCosts()) {
    // if the order has extra costs show a stepper with a confirmation button
    return (
      <div className="menu menu-cart">

        <div className="row cart-body">
          <div className="col-12 costs-stepper">
            <CostsStepper order={torder}

              onConfirm={_confirmOrder}
            />
          </div>
        </div>
        <audio id="orders_audio_player" />
      </div>
    )
  } else if (_hasConfirmStatus()) {
    // if order has been changed show the changed stepper with a confirmation button
    return (
      <div className="menu menu-cart">
        <div className="row cart-body">
          <div className="col-12 changed-stepper">
            <ChangedStepper order={torder} onConfirm={() => {
              //EventBus.emit("reconfirmOrder", order)
            }} />
          </div>
        </div>
        <audio id="orders_audio_player" />
      </div>
    )
  }

  if (order.deliverType === 'table') {
    return (
      <div className="menu menu-cart">
        <div className="row cart-body">
          <div className="col-12 table-payment-stepper">
            <PaymentStepper order={torder} />
          </div>
        </div>
        {(torder.status === OrderStatus.Dispatched || torder.status === OrderStatus.Delivered || torder.status === OrderStatus.Paid) &&
          <div className="cart_control-button-row">
            <div className="new-order-button" onClick={_clearOrder} >
              ¿Deseas algo mas?
            </div>
          </div>
        }
      </div>
    )
  }

  if (_hasPaymentStatus()) {
    // if the order requires payment or has been paid show the payment status

    return (
      <div className="menu menu-cart">
        <div className="row cart-body">
          <div className="col-12 payment-stepper">
            <PaymentStepper order={torder} />
          </div>
        </div>

        {_newOrderButton(torder)}

      </div>
    )
  }

  if (order.deliverType === 'takeaway') {
    return (
      <div className="menu menu-cart">

        <div className="row cart-body">
          <div className="col-12 takeaway-stepper">
            <TakeawayStepper order={torder} />
          </div>
        </div>

        {ignoredOrder(torder)}

        {_newOrderButton(torder)}

        <audio id="orders_audio_player" />

      </div>
    )
  }

  // default delivery order type
  // the order has been submitted show the order status
  return (
    <div className="menu menu-cart">
      <div className="row cart-body">
        <div className="col-12 standard-stepper">
          <StandardStepper order={torder} />
        </div>
      </div>

      {ignoredOrder(torder)}

      {_newOrderButton(torder)}

      {_deliveryMap(deliveryRef.current, torder)}

      <audio id="orders_audio_player" />

    </div>
  )

}


function DeliveryMap(props) {

  const { latitude, longitude, delivery, driver, title, onMapClick } = props

  const [map, setMap] = useState(null)
  const [zoom, setZoom] = useState(16); // initial zoom
  const [center, setCenter] = useState(null);
  const [marker, setMarker] = useState(null)

  useEffect(() => {

    const L = window.leaflet

    //console.log("useEffect position", latitude, longitude, name, phone)
    let center = {
      lat: latitude,
      lng: longitude,
    }
    setCenter(center)


    if (!map && center) {
      //console.log("create map")
      let map = L.map('delivery_map').setView([center.lat, center.lng], zoom);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '© OpenStreetMap'
      }).addTo(map);

      var myIcon = L.icon({
        iconUrl: 'images/delivery.png',
        iconSize: [30, 30],
        //iconAnchor: [22, 94],
        //popupAnchor: [-3, -76],
        // shadowUrl: 'my-icon-shadow.png',
        // shadowSize: [68, 95],
        // shadowAnchor: [22, 94]
      });

      // add the map marker
      let marker = L.marker([center.lat, center.lng], { icon: myIcon }).addTo(map);

      var popup = L.popup();

      function onMapClick(e) {
        popup
          .setLatLng(e.latlng)
          .setContent("Clik @ " + round(e.latlng.lat, 6) + ", " + round(e.latlng.lng, 6))
          .openOn(map);

        setCenter({
          lat: round(e.latlng.lat, 6),
          lng: round(e.latlng.lng, 6)
        })

        props.onMapClick({
          lat: round(e.latlng.lat, 6),
          lng: round(e.latlng.lng, 6)
        })
      }

      //map.on('click', onMapClick);

      map.on('zoom', (e) => {
        //console.log('zoom', e)
        setZoom(e.target.zoom)
      })

      setMap(map)
      setMarker(marker)
    }

    if (map && center) {
      if (marker) {
        marker.setLatLng(center)
      }
      map.setView([center.lat, center.lng], zoom)
    }
  }, [latitude, longitude, delivery, driver]);


  //console.log('map @', center)

  let text = `%F0%9F%91%8B%20Hola%20%0ADeseo%20hablar%20sobre%20mi%20pedido%3A%0A`
  let prefix = "https://wa.me/" + driver.phone

  return (
    <div className="order-driver-location pt-3 pt-xl-4">
      <h3 className="px-2 px-sm-2 px-lg-3 mx-xl-4 pb-1">{title}</h3>

      <div id="delivery_map" style={{ display: "flex", height: 400, marginTop: 5 }}>

        {/* Basic form for controlling center and zoom of map. */}

      </div>

      <div className="row">
        <div className="col">
          Mensajero: {driver.name}
        </div>
      </div>


      <div className="row">
        <div className="col">
          Cel: {driver.phone}
        </div>
      </div>

      <div className="row">
        <div className="col">
          Precio: {delivery.price}
        </div>
      </div>

      <div style={{
        margin: 10
      }}>
        <a href={prefix + '/?text=' + text} target="_blank" rel="noopener noreferrer"

          className="btn btn-lg row centrar" style={{ backgroundColor: "#25D366", color: "white", margin: 0, padding: "10px" }}
        >
          <i className="fa fa-whatsapp fa-lg"
            style={{ color: 'white', padding: "10px" }}
          ></i>
          Llamar Mensajero
        </a>
      </div>

    </div>
  )
}