
import React, { useState,  useEffect,  /* */ } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container,  } from 'react-bootstrap';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

//import CheckoutItem from './common/CheckoutItem';
import eye from '../img//eye.png';


import useTranslations from "../lib/useTranslations";
//import { CartContext } from '../lib/CartContext';
//import { BusinessContext } from '../lib/BusinessContext';
import {  postToApi } from "../lib/api";

//import OrderProgress from '../order/order_progress'

function History(props) {

  const [history, setHistory] = useState([])

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [orderSelected, setOrderSelected] = useState({
    'orderNumber': '',
    'Business': '',
    'totalcost': '',
    'status': '',
    'tstamp': ''
  });

  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  const ordersHistory = JSON.parse(localStorage.getItem('orderHistory'))
  let objHistory = { "orderhistory": ordersHistory }

  useEffect(() => {

    //console.log("sending", objHistory)
    postToApi(
      `/api/v1/order/for_ids`,
      { id: 0 },  // dummy user
      objHistory,
      (res) => {
        setHistory(res.result.reverse())
        //console.log(res.result)
      },
      (error) => {
        console.log(error);
      }

    );

  }, [])

 
  function toThanksPage(order) {

    // console.log(JSON.parse(order))
    // order = JSON.parse(order)

    let thanksLocation;
    console.log(order)

    if (order) {
      let deliveryData = {
        destination: order.destination,
        phone: order.phone
      }
      thanksLocation = {
        pathname: '/thanks',
        state: {
          selectedMenu: order,
          items: order.items,
          deliverType: order.deliverType,
          deliveryData: deliveryData,
          paymentMethod: order.paymentMethod,
        }
      }

      if (order.deliverType === 'takeaway') {
        thanksLocation.state.deliveryData = {
          name: order.deliverType.name,
          phone: order.deliverType.phone
        }
      }
    }

    return (
      <Link to={thanksLocation} className="col-2 text-center">
        <img src={eye} onClick={handleShow} style={{ height: "30px", width: "30px" }} alt="" />
      </Link>
    )
  }

  return (
    <section className="section pt-5 pb-5 osahan-not-found-page" style={{
      backgroundColor: 'white'
    }}>
      <Container>
        <Row>
          <Col md={12} className="pb-5 text-center">
            <h2 className="mt-2 mb-2">{tr("Orders History")}</h2>
          </Col>
        </Row>

      </Container>
      {/* <div className="row center " style={{ margin: "10px", backgroundColor: "lightgray" }}>

        <h5 className="col-2 centrar" >{tr("Name")}</h5>
        <h5 className=" col-2 centrar">
          {tr("Phone")}
        </h5>
        <h5 className="col-2  centrar">
          {tr("Order")}
        </h5>
        <h5 className="col-2 centrar">{tr("Date")}</h5>
        <h5 className="col-2 centrar">{tr("Total")}</h5>
        <h5 className=" col-2  centrar">
          {tr("Details")}
        </h5>
      </div> */}
      <Row style={{ padding: "10px", margin: "0", backgroundColor: "lightgray" }}>
        <div className="col-2 text-center mt-2 mb-2  history-label " style={{ padding: "0px" }}>
          {tr("Order")}
        </div>
        <div className="col-3 text-center mt-2 mb-2 history-label" style={{ padding: "0px" }}>
          {tr("Product")}
        </div>
        <div className="col-3 text-center mt-2 mb-2 history-label" style={{ padding: "0px" }}>
          {tr("Business")}
        </div>
        {/* <div className="col-2 text-center mt-2 mb-2 history-label" style={{ padding: "0px" }}>
          {tr("Fecha")}
        </div> */}
        <div className="col-2 text-center mt-2 mb-2 history-label" style={{ padding: "0px" }}>
          {tr("Total")}
        </div>
        <div className="col-2 text-center mt-2 mb-2 history-label" style={{ padding: "0px" }}>
          {tr("Status")}
        </div>
        {/* <Col md={3} className=" text-center">
          <h4 className="mt-2 mb-2">{tr("Orders Id")}</h4>
        </Col>
        <Col md={3} className=" text-center">
          <h4 className="mt-2 mb-2">{tr("Product")}</h4>
        </Col>
        <Col md={3} className=" text-center">
          <h4 className="mt-2 mb-2">{tr("Businness")}</h4>
        </Col>
        <Col md={3} className=" text-center">
          <h4 className="mt-2 mb-2">{tr("Status")}</h4>
        </Col> */}

      </Row>
      {history
        ? history.map((order, ind) => {
          return (
            <div key={ind}>
              <Row style={{ padding: "10px", margin: "0" }} >
                <div className="col-2 text-center ">
                  <label className="mt-2 mb-2 info-history">{order.orderNumber}</label>
                </div>
                <div className="col-3 text-center">
                  <label className="mt-2 mb-2 info-history">{order.items[0].name}</label>
                </div>
                <div className="col-3 text-center">
                  <label className="mt-2 mb-2 info-history">{order.Business.name}</label>
                </div>
                {/* <div className="col-2 text-center">
                  <label className="mt-2 mb-2 info-history">Fecha</label>
                </div> */}
                <div className="col-2 text-center">
                  <label className="mt-2 mb-2 info-history">{order.totalcost}</label>
                </div>
                {order.status === 'new' || order.status === 'ready' || order.status === 'preparing'
                  ? toThanksPage(order)
                  :
                  <div className="col-2 text-center" onClick={() => setOrderSelected(order)}>
                    {/* <label className="mt-2 mb-2 info-history" onClick={handleShow}>{order.status}</label> */}
                    <img src={eye} onClick={handleShow} style={{ height: "30px", width: "30px", cursor: "pointer" }} alt="" />
                  </div>
                }

                {/* <Col md={3} className=" text-center">
                  <h5 className="mt-2 mb-2">{order.id}</h5>
                </Col>
                <Col md={3} className=" text-center">
                  <h5 className="mt-2 mb-2">{order.items[0].name}</h5>
                </Col>
                <Col md={3} className=" text-center">
                  <h5 className="mt-2 mb-2">{order.Business.name}</h5>
                </Col>
                <Col md={3} className=" text-center">
                  <h5 className="mt-2 mb-2">{order.status}</h5>
                </Col> */}
              </Row>
              <hr />
            </div>
          )
        })
        : <Container>
          <Row>
            <Col md={12} className="pb-5">
              <h1 className="mt-2 mb-2">{tr("Orders History Empty")}</h1>
            </Col>
          </Row>

        </Container>}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{tr('Order Information')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <Modal.Title className='col-6' style={{ fontSize: "15px" }}>{tr('Order number')}</Modal.Title>
            <div className='col-6' style={{ fontSize: "15px" }}>{orderSelected.orderNumber}</div>
            <Modal.Title className='col-6' style={{ fontSize: "15px" }}>{tr('Business')}</Modal.Title>
            <div className='col-6' style={{ fontSize: "15px" }}>{orderSelected.Business.name}</div>
            <Modal.Title className='col-6' style={{ fontSize: "15px" }}>{tr('Total')}</Modal.Title>
            <div className='col-6' style={{ fontSize: "15px" }}>{orderSelected.totalcost}</div>
            <Modal.Title className='col-6' style={{ fontSize: "15px" }}>{tr('Status')}</Modal.Title>
            <div className='col-6' style={{ fontSize: "15px" }}>{orderSelected.status}</div>
            <Modal.Title className='col-6' style={{ fontSize: "15px" }}>{tr('Date')}</Modal.Title>
            <div className='col-6' style={{ fontSize: "15px" }}>{orderSelected.tstamp}</div>
            <Modal.Title className='col-6' style={{ fontSize: "15px" }}>{tr('Destination')}</Modal.Title>
            <div className='col-6' style={{ fontSize: "15px" }}>{orderSelected.destination}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>

    </section>
  );
}

export default History;