
import React, { useContext, useState, useEffect, useRef } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';


import { QRCodeSVG } from 'qrcode.react';
import { Badge, Image } from 'react-bootstrap';
import { getFromApi } from './lib/api';
import { BusinessContext } from './lib/BusinessContext';
import { CartContext, CartProvider } from './lib/CartContext';
import useTranslations from "./lib/useTranslations";

//import Header from './components/common/Header';
import TopSearch from './components/home/TopSearch';
import BareModal from './components/modals/BareModal';
import Icofont from 'react-icofont';
import Detail from './pages/Detail'

const BusinessCard = (props) => {
  const { photo, name, description } = props.business;
  const { onSelected } = props

  const { business } = props;

  const [canOrder, setCanOrder] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [openIn, setOpenIn] = useState(null)
  const [closeIn, setCloseIn] = useState(null)

  const { bestDeliveryMenu, checkMenuPeriods } = useContext(BusinessContext);
  const { tr } = useTranslations();

  //const { tr } = useTranslations();
  useEffect(() => {
    // check this business can order
    let hasOrdering = true // businessHasOrdering(business)
    setCanOrder(hasOrdering)

    if (hasOrdering) {
      // check they are open
      if (business.menus) {
        let menu = bestDeliveryMenu(business, business.menus)
        let res = checkMenuPeriods(menu, business)

        //console.log("checkPeriod res", business.name, res.isOpen ? "OPEN" : "CLOSED",res )
        //console.log("menu periods", menu)

        if (res.isOpen) {
          setIsOpen(true)
          if (res.closeIn && res.closeIn > 0 && res.closeIn < 3600) {
            setCloseIn(`${Math.floor(res.closeIn / 60)} min`)
          }
        } else {
          if (res.openIn && res.openIn > 0 && res.openIn < 7200) {
            setOpenIn(`${Math.floor(res.openIn / 60)} min`)
          }
        }
      }

    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [business])

  const timeInfo = () => {
    if (openIn) {
      return (
        <p className="card-text" style={{ color: 'green' }}>
          <span className="bg-light  rounded-sm pl-2 pt-1 pr-2">
            <Icofont icon='wall-clock' /> {tr("Opens in")} {openIn}
          </span>
        </p>
      )

    } else if (closeIn) {
      return (
        <p className="card-text" style={{ color: 'red' }}>
          <span className="bg-light rounded-sm pl-2 pt-1 pr-2">
            <Icofont icon='wall-clock' /> {tr("Closes in")} {closeIn}
          </span>
        </p>
      )
    } else {
      return (
        <p className="card-text">{description}</p>
      )
    }
  }

  //console.log("businnes", name, canOrder, isOpen)

  if (canOrder && isOpen) {
    return (
      <div className="card card-business online"
        onClick={() => {
          //console.log("clicked", name)
          onSelected()
        }}
      >
        <img className="card-img-top" src={photo} alt="Card image cap" />
        <div className="card-body">
          <h1 className="card-name"> {name} </h1>
          {timeInfo()}
        </div>
      </div>
    );
  }

  return (
    <div className="card card-business offline"
      style={{
        opacity: "0.5"
      }}
    >
      <div className="member-plan position-absolute">
        <Badge pill variant='danger' >Offline</Badge>
      </div>
      <img className="card-img-top" src={photo} alt="Card image cap" />
      <div className="card-body">
        <h1 className="card-name"> {name} </h1>
        {timeInfo()}
      </div>
    </div>
  );
};

const Home = (props) => {
  /* eslint-disable no-unused-vars */
  //const [currentLocation, setCurrentLocation] = useState({});
  const [loading, setLoading] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)

  // const [chosenProvince, setChosenProvince] = useState(null)
  // const [chosenCity, setChosenCity] = useState(null)

  const { businesses, clearSavedData, departments, cities, selectedBusiness, selectedMenu, setSelectedMenu,
    setSelectedBusiness, selectedProvince, setSelectedProvince, provinceSelected,
    selectedCity,
    setSelectedCity,
    setCityName, } = useContext(BusinessContext);

  const { clearCartData } = useContext(CartContext);

  const { tr, getTranslations, locale, translations, setLocale } = useTranslations();

  const history = useHistory();


  const clearAllData = () => {
    clearSavedData()
    clearCartData()
  }

  const downloadAndNavigateToMenu = (menuId) => {

    localStorage.removeItem("selectedMenu")

    getFromApi(`/api/v1/menu/with_business_and_location/${menuId}`, {}, (data) => {
      //console.log("menu result", menuId, data.result)

      // find the given menu
      //// IMPORTANT do not change this test to === otherwise use parseInt()
      /* eslint-disable eqeqeq */
      let menu = data.result

      // set the department and city
      setSelectedProvince(menu.Location.province)
      setSelectedCity(menu.Location.city)

      setSelectedMenu(menu)
    }, (error) => {
      console.log('get menu failed', error)
    })

  }

  useEffect(() => {

    // on page load clear the cart
    //localStorage.removeItem("cartItems")
    //localStorage.removeItem("locationData")

    localStorage.removeItem("selectedMenu")
    //localStorage.removeItem("paymentMethod")
    //localStorage.removeItem("deliveryAddress")
    //localStorage.removeItem("pendingOrder")

    getTranslations();

    // get the data from the parameters
    const urlParams = new URLSearchParams(window.location.search);
    let menuId = urlParams.get('mid');
    const locationId = urlParams.get('lid');
    const businessId = urlParams.get('bid');
    const tableId = urlParams.get("tid");
    const orderId = urlParams.get("oid")
    const rating = urlParams.get("rate")
    const expo = urlParams.get("expo")

    if (businessId != null && locationId != null && menuId != null) {
      //console.log("params", businessId, locationId, menuId)
      setLoading(true)

      // handle Gordo links
      if (businessId === 303 && locationId === 265 && menuId === 141) {
        menuId = 166
      } else if (businessId === 303 && locationId === 267 && menuId === 143) {
        menuId = 167
      }

      if (tableId != null) {
        localStorage.setItem("tableNum", tableId)
      } else {
        localStorage.removeItem("tableNum")
      }

      // clear the cart in case any item in the cart is not in the same menu
      clearCartData(menuId)

      downloadAndNavigateToMenu(menuId)

    } else if (orderId && rating) {
      // go to the rating page to rate the order and delivery

    } else {
       window.location.href = "https://zono.pro"
    }

  }, [])

  /* 
    if (selectedBusiness || selectedMenu) {
      //console.log("selected business", selectedBusiness)
  
      return (
        <BusinessPage business={selectedBusiness} />
      )
    }
  
    if (businesses && businesses.length > 0) {
      console.log("businesses", businesses)
      return (
        <>
          <TopSearch />
  
          <section className="section pt-3 pb-5 bg-white homepage-add-section ">
            {_getBusinessGrid(businesses)}
          </section>
        </>
      )
    }
   */

  if (selectedMenu) {
    return <Detail business={selectedMenu.Business} menu={selectedMenu} />
  }

  if (loading) {
    return (
      <div className="even-column" >
        <div></div>
        <div className="row">
          <div className="col"></div>
           
          <div className="col"></div>
        </div>
        <div></div>
      </div>
    )
  }
  let url = "https://zono.pro"

  return (
    <div className="even-column" >
      <div></div>
      <div className="row">
        <div className="col"></div>
        <div className="col province-selector centered" onClick={() => {
          window.location.href = url
        }}>
          Continuar
        </div>
        <div className="col"></div>
      </div>
      <div></div>
    </div>
  )

}

export default Home

export const Header = (props) => {
  return (
    <CartProvider>
      <HeaderPage />
    </CartProvider>
  )
}

const HeaderPage = (props) => {
  const ref = useRef(0);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { departments, selectedProvince, provinceSelected, selectedCity, cities, setCityName, selectedBusiness, setSelectedBusiness,
    setSelectedCity, setBusinesses, setSelectedMenu } = useContext(BusinessContext);
  const { cartItems, getProductCost, totalItemsCost } = useContext(CartContext);

  const { tr, getTranslations, locale, translations, setLocale } = useTranslations();

  const location = useLocation();

  const closeMenu = () => {
    setIsNavExpanded(false);
  }

  const handleClick = (e) => {
    if (ref.current.contains(e.target)) {
      // if clicked inside menu do something
    } else {
      setIsNavExpanded(false);
    }

  }

  useEffect(() => {
    document.addEventListener('click', handleClick, false);
    // returned function will be called on component unmount 
    return () => {
      document.removeEventListener('click', handleClick, false);
    }
  }, [selectedBusiness])


  // console.log("render header", departments, selectedProvince, cities, selectedCity)

  return (
    <div ref={ref}>
      <Navbar onToggle={() => setIsNavExpanded(true)}
        expanded={isNavExpanded} color="light" expand='lg' className="navbar-light osahan-nav shadow-sm">
        <Container>
          <Navbar.Brand style={{ cursor: 'pointer' }}   >
            <div onClick={() => {
              localStorage.removeItem('selectedMenu')
              setSelectedMenu(null)
              setSelectedBusiness(null)
              setSelectedCity('')
              setBusinesses([])

              if (location.pathname !== "/") {
                window.location.href = "/"
              }

            }}>
              <Image src="/images/zono-logo-smile.png" alt='' />
            </div>
          </Navbar.Brand>

          <div className="show-only-large-devices">
            <span>Promos Exclusivas , Mas Rapido, Mas Facil</span>
          </div>


          <div className="show-only-small-devices">
            <span>Promos Exclusivas</span><br />
            <span>Mas Rapido, Mas Facil</span><br />
            <span></span><br />

          </div>



          <Navbar.Toggle />
          <Navbar.Collapse id="navbarNavDropdown">
            <Nav activeKey={0} className="ml-auto" onSelect={closeMenu}>
              <a className="nav-link" href="https://zono.pro">
                Quiero mi página Zono<span className="sr-only">(current)</span>
              </a>

              <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="/history">
                {tr('My Orders')} <span className="sr-only">(current)</span>
              </Nav.Link>
              {/* <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="/listing">
                {tr('Restaurants')} <span className="sr-only">(current)</span>
              </Nav.Link> */}
              {/* <Nav.Link eventKey={1} as={NavLink} activeclassname="active" >
                <Icofont icon='sale-discount' /> {tr('Restaurants')} <Badge variant="danger"></Badge>
              </Nav.Link> */}
              {/* <Nav.Link eventKey={1} as={NavLink} activeclassname="active" to="/offers">
                <Icofont icon='sale-discount' /> {tr('Offers')} <Badge variant="danger">{tr('New')}</Badge>
              </Nav.Link> */}
              {/* <NavDropdown title={tr('Restaurants')} alignRight className="border-0">
                <NavDropdown.Item eventKey={2.1} as={NavLink} activeclassname="active" to="/listing">{tr('Listing')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={2.2} as={NavLink} activeclassname="active" to="/detail">{tr('Detail + Cart')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={2.3} as={NavLink} activeclassname="active" to="/checkout">{tr('Checkout')}</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={tr('Pages')} alignRight>
                <NavDropdown.Item eventKey={3.1} as={NavLink} activeclassname="active" to="/track-order">{tr('Track Order')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={3.2} as={NavLink} activeclassname="active" to="/invoice">{tr('Invoice')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={3.3} as={NavLink} activeclassname="active" to="/login">{tr('Login')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={3.4} as={NavLink} activeclassname="active" to="/register">{tr('Register')}</NavDropdown.Item>                
                <NavDropdown.Item eventKey={3.6} as={NavLink} activeclassname="active" to="/extra">{tr('Extra')}</NavDropdown.Item>

              </NavDropdown> */}
              {/* <NavDropdown alignRight
                title={
                  <DropDownTitle
                    className='d-inline-block'
                    image="img/user/4.png"
                    imageAlt='user'
                    imageClass="nav-osahan-pic rounded-pill"
                    title={tr('My Account')}
                  />
                }
              >
                <NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/myaccount/orders"><Icofont icon='food-cart' /> {tr('Orders')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={4.2} as={NavLink} activeclassname="active" to="/myaccount/offers"><Icofont icon='sale-discount' /> {tr('Offers')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={4.3} as={NavLink} activeclassname="active" to="/myaccount/favourites"><Icofont icon='heart' /> {tr('Favourites')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={4.4} as={NavLink} activeclassname="active" to="/myaccount/payments"><Icofont icon='credit-card' /> {tr('Payments')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={4.5} as={NavLink} activeclassname="active" to="/myaccount/addresses"><Icofont icon='location-pin' /> {tr('Addresses')}</NavDropdown.Item>
              </NavDropdown>

              {_cartDropdown()} */}

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

/*

  const _oldHomePage = () => {

    return (
      <div className="home-body">
        <Header />

        <div className="home-body-content mt-5 pl-4">
         

          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col-12">
                  <h1>Pide de tu Restaurante Favorito al Mejor Precio.</h1>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12">
                  <h3>Conozca toda las ofertas, en la App.</h3>

                </div>
              </div>


              <div className=" centered mt-1">
                <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
                  <div className="">
                    <div className="centered"  >
                      <span style={{
                        padding: 10,
                        backgroundColor: 'black',
                        color: 'white',
                        borderRadius: 10,
                        fontSize: 15,
                      }}   >Redime Ofertas Exclusivas</span>

                    </div>
                  </div>
                </a>
              </div>

              <div className="row mt-4">
                <div className="col-12">
                  <span className="fs-1-5 fw-400 poppins white">Conoce aliados cerca de ti.</span>

                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12">
                  <select className="province-selector"
                    onChange={(event) => {
                      clearAllData()
                      setSelectedCity('')
                      provinceSelected(event.target.value);

                      const location = {
                        pathname: `/p/${event.target.value}/show`,
                        state: { province: event.target.value }
                      }

                      history.push(location)
                    }}
                  >
                    <option value="-">Elije tu departamento</option>

                    {departments.map((dept, index) => {
                      if (selectedProvince == dept) {
                        return (
                          (
                            <option key={'dept_' + index} value={dept} selected>{dept}</option>
                          )
                        )
                      } else {
                        return ((
                          <option key={'dept_' + index} value={dept}>{dept}</option>
                        ))
                      }

                    })}
                  </select>

                </div>
              </div>

              {selectedProvince && cities &&
                <div className="row mt-4">
                  <div className="col-12">
                    <select className="province-selector"
                      defaultValue="-"
                      onChange={(event) => {
                        let city = event.target.value
                        if (city == '-') city = ''
                        clearAllData()
                        //console.log("setSelectedCity")
                        setSelectedCity(city)

                        let province = selectedProvince

                        if (city == '-') { city = '' } else {
                          const location = {
                            pathname: `/c/${province}/${city}/show`,
                            state: { city: city, province: province }
                          }

                          history.push(location)
                        }
                      }}
                    >
                      {selectedCity == '' &&
                        <option value="-" >Elije tu ciudad</option>
                      }

                      {cities.map((city, index) => (
                        <option key={'dept_' + index} value={city}>{city}</option>
                      ))}
                    </select>

                  </div>
                </div>

              }

              <div className="row mt-4 show-only-large-devices">
                <div className="col-12">
                  <h3>¡Pide Yá!</h3>

                </div>
              </div>

            </div>


            <div className="col show-only-large-devices">
              <div className="centered" style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%'
              }}>

                <div className="centered">
                  <QRCodeSVG value="https://fbh.xoonoo.com/1YMJ" />
                </div>

                <div className="mt-4 centered">
                  <img src="/images/somos_aliados.png" style={{ width: 300 }}></img>
                </div>

              </div>

            </div>

            <div className="col show-only-large-devices">
              <div className="row">
                <div className="col">
                  <div className="mt-4 centered">
                    <img src="/images/Club_screen1.png" style={{ width: 200 }}></img>
                  </div>

                  <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
                    <div className="mt-4 centered">
                      <img src="/images/google-play-badge.png" style={{ width: 150 }}></img>
                    </div>
                  </a>
                </div>
                <div className="col">
                  <div className="mt-4 centered">
                    <img src="/images/Club_screen2.png" style={{ width: 200 }}></img>
                  </div>

                  <a href="https://fbh.zono.cc/?link=https://zono.cc/download&apn=cc.zono.mobile&isi=6463274860&ibi=com.xoonoo.club" target="_blank">
                    <div className="mt-4 centered">
                      <img src="/images/appstore-badge.png" style={{ width: 150 }}></img>
                    </div>
                  </a>

                </div>
              </div>


            </div>


          </div>


          <div className="row mt-4 show-only-small-devices">
            <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
              <div className="col-6" >
                <div className="mt-1 centered">
                  <img src="/images/google-play-badge.png" style={{ width: 150 }}></img>
                </div>

              </div>
            </a>

            <a href="https://fbh.zono.cc/?link=https://zono.cc/download&apn=cc.zono.mobile&isi=6463274860&ibi=com.xoonoo.club" target="_blank">
              <div className="col-6">
                <div className="mt-1 centered">
                  <img src="/images/appstore-badge.png" style={{ width: 150 }}></img>
                </div>

              </div>
            </a>

            <div>
              <span>

              </span>
            </div>

          </div>

          <BareModal show={openPopup} onHide={setOpenPopup} classNames='bg-black fg-white'  >
            <div className="row" style={{ padding: 2 }}>
              <div className="col-12 align-end">
                <span onClick={() => setOpenPopup(false)
                }
                  style={{ padding: 2 }}
                >X</span>
              </div>
            </div>
            <div className="row">
              <div className="col-12 centered">

                <Image src="/images/new-xoonoo-logo.png" alt='' />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 centered">
                ¡Las Mejores Promos!</div>
            </div>
            <div className="row mt-2">
              <div className="col-12 centered">2X1 - 50% OFF! - HAPPY HOUR</div>
            </div>
            <div className="row mt-2">
              <div className="col-12 centered">y Mucho Mas...</div>
            </div>
            <div className="row mt-4">
              <div className="col-12 centered">
                <a href="https://fbh.xoonoo.com/1YMJ" target="_blank">
                  <span style={{
                    padding: 5,
                    cursor: 'pointer',
                    backgroundColor: '#F1AD3E',
                    borderRadius: 5,
                    color: 'black'
                  }} >Descarga La App
                  </span>
                </a>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 centered"
              >
                <span onClick={() => setOpenPopup(false)
                }
                  style={{ padding: 2, color: '#929292' }}
                >No quiero ofertas</span>

              </div>
            </div>



          </BareModal>

        </div>

        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="pb-2 text-center ">
              <div>
                <a href="/docs/Colombia/terminos.html" target="blank">
                  <p className="mt-0 mb-0">Términos y Condiciones</p>
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="pb-2  text-center ">
              <div>
                <a href="/docs/Colombia/privacy.html" target="blank">
                  <p className="mt-0 mb-0"> Políticas de Privacidad</p>
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-3">
            <div className="pb-2  text-center ">
              <div>
                <a href="/docs/Colombia/data_usage.html" target="blank">
                  <p className="mt-0 mb-0">Tratamiento de Datos</p>
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-3">
            <div className="pb-2  text-center ">
              <div>
                <a href="/docs/Colombia/cookies.html" target="blank">
                  <p className="mt-0 mb-0">Política de Cookies</p>
                </a>

              </div>
            </div>
          </div>
        </div>

        <div className="pb-2 mt-2 text-center ">
          <div>
            <p className="mt-0 mb-0">© Copyright 2022 ZONO VENTURES SAS. All Rights Reserved</p>
          </div>
        </div>


        <div className="row  powered-row">
          <div className="col centered"><span>Powered By</span></div>
        </div>

        <div className="row logo-row">
          <div className="col centered">
            <a href="https://zono.pro" target="blank">
              <img src="/images/zono-smile.png" width={100} />
            </a>
          </div>
        </div>

      </div>
    );
  }

  const _getBusinessGrid = (businesses) => {
    const breakpointColumnsObj = {
      default: 5,
      1200: 4,
      900: 3,
      700: 2,
      500: 1
    };

    if (loading) {
      return (
        <>
          <div className='row justify-content-center' style={{ margin: 0 }}>
            <div className='loader '></div>

          </div>
          <div className='row justify-content-center' style={{ margin: 0 }}>
            <div>
              Cargando...
            </div>
          </div>
        </>
      )
    }

    else {
      return (
        <div
          className="businesses"
        >
          {businesses.map((business, index) => (
            <BusinessCard business={business} key={`hp_biz_` + index} onSelected={() => {
              //console.log("selected business", business)
              setSelectedBusiness(business)
            }} />
          ))}
        </div>
      )
    }
  }

  */