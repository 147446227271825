import React, { useState } from 'react'

import Masonry from 'react-masonry-css'

import { groupBy } from '../lib/utils'

const breakpointColumnsObj = {
  default: 5,
  //    1200: 5,
  900: 4,
  700: 3,
  500: 2
};

function ProductOptionItem(props) {

  const { product, option, group, onSelected } = props

  // this option is selected if it is in the product.selectedOptions array
  let selectedOptions = product.selectedOptions || []
  let found = selectedOptions.find(o => o.id === option.id)
/* 
  if (found) {
    return (
      <div className="product-option-grid-item"  >
        <input type="checkbox"
          id={option.name}
          value={option.name}
          onChange={() => onSelected(option, group)} checked
        />
        <label htmlFor={option.name}>{option.name} + ${option.price}</label>
      </div>
    )
  } */

  return (
    <div className="product-option-grid-item"  >
      <input type="checkbox"
        id={option.name}
        checked={found != null}
        onChange={() => onSelected(option, group)}
      />
      <label htmlFor={option.name}>{option.name} + ${option.price}</label>
    </div>
  )
}

function OptionDivTitle(props) {
  // if index > 0 start with a <hr>

  let title = `Debes de seleccionar 1 de estas opciones: ${props.groupName}`

  if (props.mandatory && props.mutex) {
    // noop
  } else if (props.mandatory) {
    title = `Debes de seleccionar las opciones de ${props.groupName}`
  } else if (props.mutex) {
    title = `Puedes seleccionar 1 de estas opciones: ${props.groupName}`
  } else {
    title = `Seleccionar otras opciones de ${props.groupName}`
  }

  return (
    <div >
      {props.index > 0 && <hr />}
      <h5 style={{ margin: "10px" }}>{title}</h5>
    </div>
  )
}

function OptionDescriptions(props) {
  const { product, options } = props

  // remove any options with an empty description
  let opts = options.filter((o) => o.description.length > 0)

  //console.log("filtered options", opts)

  return (
    <div style={{
      marginBottom: 10
    }} >
      {opts.map((o) =>
        <div className="row" key={`option_desc_${o.id}`}>
          <div className="col"><small>{o.name} : {o.description}</small></div>
        </div>
      )}
    </div>
  )
}


function ProductOptionSelector(props) {
  const { onSelected, product, group, options } = props

  // if we already have selected max product options then return empty div
  let selectedOptions = product.selectedOptions || []

  return <select style={{
    width: '95%'
  }}
    onChange={(event) => {
      //console.log("selected", event.target.value)
      onSelected(event.target.value, group)
    }} >
    <option></option>
    {options.map((opt, index) => {

      let found = selectedOptions.find(o => o.id === opt.id)

      if (found) {
        return (
          <option key={"p_" + product.id + "_option" + opt.id + index} value={opt.id}
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
            selected
          > {opt.name} + ${opt.price}
          </option>
        )
      }
      return (
        <option key={"p_" + product.id + "_option" + opt.id + index} value={opt.id}
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}

        > {opt.name} + ${opt.price}
        </option>
      )

    }

    )
    }
  </select>
}

function OptionGroup(props) {
  const { product, group, options } = props

  if (options.length > 6) {
    return (
      <div className="product-options">
        <OptionDivTitle {...props} />

        <ProductOptionSelector
          product={product}
          options={options}
          group={group}
          onSelected={props.onSelected}
        />

        <OptionDescriptions {...props} />

      </div>
    )

  }

  return (
    <div className="product-options">
      <OptionDivTitle {...props} />

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="product-option-grid_column"
      >

        {options.map((opt) =>
          <ProductOptionItem
            key={"p_" + product.id + "_option" + opt.id}
            product={product}
            option={opt}
            group={group}
            onSelected={props.onSelected}
          />
        )
        }

      </Masonry>

      <OptionDescriptions {...props} />

    </div>
  )
}


export default function ProductOptions(props) {

  const [showOptions, /* setShowOptions */] = useState(true)

  const product = props.product;
  if (!product.OptionGroups || product.OptionGroups.length === 0)
    return (<div></div>)

  if (!showOptions) return (<div></div>)

  const groups = product.OptionGroups

  groups.sort((a, b) => a.sequence - b.sequence)

  //console.log("final options", groups)

  return (
    groups.map((group, index) => {

      let options = group.Options.filter((opt) => opt.available === true)

      if (options.length == 0) return <div key={"p_" + product.id + "_optiongroup" + index}></div>

      options.sort((a, b) => a.sequence - b.sequence)

      // ensure the group name has a value
      options.forEach(opt => {
        opt.group_name = group.name
      });

      if (group.mutex) {
        return <OptionGroup
          key={"p_" + product.id + "_optiongroup" + index}
          product={product}
          options={options}
          detailColour={props.detailColour}
          ordersAvailable={props.ordersAvailable}
          mandatory={group.mandatory}
          index={index}
          mutex={true}
          groupName={group.name}
          group={group}
          onSelected={props.onSelected}
        />
      } else {
        return <OptionGroup
          key={"p_" + product.id + "_optiongroup" + index}
          product={product}
          options={options}
          detailColour={props.detailColour}
          ordersAvailable={props.ordersAvailable}
          mandatory={group.mandatory}
          index={index}
          mutex={false}
          groupName={group.name}
          group={group}
          onSelected={props.onSelected}
        />
      }
    }
    )
  )

}
