import React /*, { createContext,  useState, useEffect } */ from 'react';

import { BrowserRouter as Router, Route } from 'react-router-dom';
//import Footer from './components/common/Footer';
//import Index from './components/Index';
import Offers from './pages/Offers';
import MyAccount from './pages/MyAccount';
import List from './components/List';
import NotFound from './pages/NotFound';
import Thanks from './pages/Thanks';
import History from './pages/History';
import Extra from './components/Extra';
import Login from './pages/Login';
import Register from './pages/Register';
import TrackOrder from './pages/TrackOrder';
import Invoice from './pages/Invoice';
import Checkout from './pages/Checkout';
import Detail from './pages/Detail';

import ProvincePage from './pages/ProvincePage'
import CityPage from './pages/CityPage'
import BusinessPage from './pages/BusinessPage'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-select2-wrapper/css/select2.css';
import './App.css';
//import { getFromApi } from './lib/api';
/* eslint-disable no-unused-vars */
import { BusinessProvider } from './lib/BusinessContext';

import { ConfigProvider } from "./lib/ConfigContext";
import { CartProvider } from './lib/CartContext';

import Home from './Home'

const App = (props) => {

  return (

    <BusinessProvider>
      <CartProvider>
        <ConfigProvider>
         <div style={{marginBottom: 50, width: '100%', height: '100%'}}>
         <Router>
           
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/offers" exact component={Offers} />
            <Route path="/listing" exact component={List} />
            <Route path="/myaccount" component={MyAccount} />
            <Route path="/404" exact component={NotFound} />
            <Route path="/extra" exact component={Extra} />
            <Route path="/login" exact component={Login} />
            <Route path="/register" exact component={Register} />
            <Route path="/track-order" exact component={TrackOrder} />
            <Route path="/invoice" exact component={Invoice} />
            <Route path="/checkout" exact component={Checkout} />
            <Route path="/thanks" exact component={Thanks} />
            <Route path="/history" exact component={History} />
            <Route path="/detail" exact component={Detail} />

            <Route path="/p/:province/show" exact component={ProvincePage} />

            <Route path="/c/:province/:city/show" exact component={CityPage} />

            <Route path="/b/:name/:city/show" exact component={BusinessPage} />

          </Router>
         </div>
          
        </ConfigProvider>
      </CartProvider>
    </BusinessProvider>
  );
}

export default App;

/*

    <BusinessProvider>
      {
        (props.location.pathname !== '/login' && props.location.pathname !== '/register') ? <Header /> : ''
      }
      <Router>
        <Route path="/" exact>
          <Index />
        </Route>
        <Route path="/offers" exact component={Offers} />
        <Route path="/listing" exact component={List} />
        <Route path="/myaccount" component={MyAccount} />
        <Route path="/404" exact component={NotFound} />
        <Route path="/extra" exact component={Extra} />
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={Register} />
        <Route path="/track-order" exact component={TrackOrder} />
        <Route path="/invoice" exact component={Invoice} />
        <Route path="/checkout" exact component={Checkout} />
        <Route path="/thanks" exact component={Thanks} />
        <Route path="/detail" exact component={Detail} />
        <Route exact component={NotFound} />
      </Router>
      {
        (props.location.pathname !== '/login' && props.location.pathname !== '/register') ? <Footer /> : ''
      }

    </BusinessProvider>
*/