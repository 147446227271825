import React, { useContext, useState, useEffect, useRef } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container, NavDropdown, Image } from 'react-bootstrap';

import { BusinessContext } from '../../lib/BusinessContext';
import { CartProvider, CartContext } from '../../lib/CartContext';

import useTranslations from "../../lib/useTranslations";

const Header = (props) => {
  return (
    <CartProvider>
      <HeaderPage {...props} />
    </CartProvider>
  )
}

const HeaderPage = (props) => {
  const {business, menu } = props;

  const ref = useRef(0);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { departments, selectedProvince, provinceSelected, selectedCity, cities, setCityName, selectedBusiness, setSelectedBusiness,
    setSelectedCity, setBusinesses, setSelectedMenu } = useContext(BusinessContext);
  const { cartItems, getProductCost, totalItemsCost } = useContext(CartContext);

  const { tr, getTranslations, locale, translations, setLocale } = useTranslations();

  const location = useLocation();

  const closeMenu = () => {
    setIsNavExpanded(false);
  }

  const handleClick = (e) => {
    if (ref.current.contains(e.target)) {
      // if clicked inside menu do something
    } else {
      setIsNavExpanded(false);
    }

  }

  useEffect(() => {
    document.addEventListener('click', handleClick, false);
    // returned function will be called on component unmount 
    return () => {
      document.removeEventListener('click', handleClick, false);
    }
  }, [selectedBusiness])

  const headerImage = () => {
    if (business) {
      return (
        <Image src={business.photo} alt='' style={{
          maxWidth: 110,
          maxHeight: 110
        }} />
      )
    }
    return (
      <Image src="/images/zono-logo-smile.png" alt='' />
    )
  }
 
  console.log("render header business", business, props)


  return (
    <div ref={ref}>
      <Navbar onToggle={() => setIsNavExpanded(true)}
        expanded={isNavExpanded} color="dark" expand='lg' className="navbar-dark  shadow-sm">
        <Container>
          <Navbar.Brand style={{ cursor: 'pointer' }}   >
            <div onClick={() => {
              if (business && business.uuid && business.uuid != "") {
                window.location.href = `https://${business.uuid}.zono.cc`
              }
             

            }}>
             {headerImage()}
            </div>
          </Navbar.Brand>


          <Navbar.Toggle />
          <Navbar.Collapse id="navbarNavDropdown">
            <Nav activeKey={0} className="ml-auto" onSelect={closeMenu}>
              <a className="nav-link" href="https://zono.pro">
              Quiero mi página Zono<span className="sr-only">(current)</span>
              </a>

              <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="/history">
                {tr('My Orders')} <span className="sr-only">(current)</span>
              </Nav.Link>
              {/* <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="/listing">
                {tr('Restaurants')} <span className="sr-only">(current)</span>
              </Nav.Link> */}
              {/* <Nav.Link eventKey={1} as={NavLink} activeclassname="active" >
                <Icofont icon='sale-discount' /> {tr('Restaurants')} <Badge variant="danger"></Badge>
              </Nav.Link> */}
              {/* <Nav.Link eventKey={1} as={NavLink} activeclassname="active" to="/offers">
                <Icofont icon='sale-discount' /> {tr('Offers')} <Badge variant="danger">{tr('New')}</Badge>
              </Nav.Link> */}
              {/* <NavDropdown title={tr('Restaurants')} alignRight className="border-0">
                <NavDropdown.Item eventKey={2.1} as={NavLink} activeclassname="active" to="/listing">{tr('Listing')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={2.2} as={NavLink} activeclassname="active" to="/detail">{tr('Detail + Cart')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={2.3} as={NavLink} activeclassname="active" to="/checkout">{tr('Checkout')}</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={tr('Pages')} alignRight>
                <NavDropdown.Item eventKey={3.1} as={NavLink} activeclassname="active" to="/track-order">{tr('Track Order')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={3.2} as={NavLink} activeclassname="active" to="/invoice">{tr('Invoice')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={3.3} as={NavLink} activeclassname="active" to="/login">{tr('Login')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={3.4} as={NavLink} activeclassname="active" to="/register">{tr('Register')}</NavDropdown.Item>                
                <NavDropdown.Item eventKey={3.6} as={NavLink} activeclassname="active" to="/extra">{tr('Extra')}</NavDropdown.Item>

              </NavDropdown> */}
              {/* <NavDropdown alignRight
                title={
                  <DropDownTitle
                    className='d-inline-block'
                    image="img/user/4.png"
                    imageAlt='user'
                    imageClass="nav-osahan-pic rounded-pill"
                    title={tr('My Account')}
                  />
                }
              >
                <NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/myaccount/orders"><Icofont icon='food-cart' /> {tr('Orders')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={4.2} as={NavLink} activeclassname="active" to="/myaccount/offers"><Icofont icon='sale-discount' /> {tr('Offers')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={4.3} as={NavLink} activeclassname="active" to="/myaccount/favourites"><Icofont icon='heart' /> {tr('Favourites')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={4.4} as={NavLink} activeclassname="active" to="/myaccount/payments"><Icofont icon='credit-card' /> {tr('Payments')}</NavDropdown.Item>
                <NavDropdown.Item eventKey={4.5} as={NavLink} activeclassname="active" to="/myaccount/addresses"><Icofont icon='location-pin' /> {tr('Addresses')}</NavDropdown.Item>
              </NavDropdown>

              {_cartDropdown()} */}

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;