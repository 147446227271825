import React, { useState, useEffect /* useContext,   */ } from 'react';
import { Image, Badge, Button, Media } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import useTranslations from "../../lib/useTranslations";

import Product from '../../order/product'

function QuickBite(props) {
  const { detailColour, hasOrdering, optionsFirst, categories, isOffer } = props;

  const [product, setProduct] = useState(props.product)
  const [openProduct, setOpenProduct] = useState(false)
  const [displayPrice, setDisplayPrice] = useState(props.price)
  const [numItems, setNumItems] = useState(1)

  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  const IncrementItem = () => {
    setNumItems(numItems + 1);
  }


  useEffect(() => {
    setProduct(props.product)

  }, [props.product])

  const DecreaseItem = () => {
    //setQuantity(quantity - 1 );
    if (numItems > 1) {
      setNumItems(numItems - 1);
    }

    /*  if(quantity <= (min +1)) {
 
     }else {
         setState({ quantity: quantity - 1 });
       setValue({id:id,quantity: (quantity - 1 )});
     } */
  }

  const _productSize = (product) => {
    if (product.chosenSize) return (<span>({product.chosenSize})</span>)
    else if (product.size) return (<span>({product.size})</span>)
    return <></>
  }

  const getDisplayedBasePrice = () => {
    // if this is a combo product and the price is 0 then display a message
    if (isOffer) {
      return tr("Only available in the XooNoo mobile app")
    }
    if (displayPrice == 0) {
      return tr("Price may vary")
    }
    return `${props.priceUnit}${displayPrice}`

  }

  // if (openProduct) 
  // console.log("quickbite openProduct with", product)

  return (
    <div className={"p-3 pr-4 border-bottom QuickBite " + props.itemClass}>

      <span className="float-right">
        <Button variant='outline-secondary' onClick={() => {
          setOpenProduct(!openProduct)
        }} size="sm">
          {openProduct ? 'X' : '+'}
        </Button>
      </span>

      {openProduct
        ? <div>
          <Media className='row product-header' >
            {props.image ?
              <Image onClick={() => {
                setOpenProduct(!openProduct)
              }}

                /* className="col-10 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8" */ src={props.image} alt={props.imageAlt}
                style={{ borderRadius: "8px", marginRight: "16px", height: "150px", padding: 0, marginBottom: "5px" }}
              />
              :
              <div onClick={() => {
                setOpenProduct(!openProduct)
              }} className="mr-3"><Icofont icon="ui-press" className={"text-" + props.badgeVariant + " food-item"} /></div>
            }
            <Media.Body className="col-10" style={{ padding: 0, margin: "5px", minWidth: "145px" }}>
              <h6 className="mb-1 " onClick={() => {
                setOpenProduct(!openProduct)
              }}>{props.title} {props.showBadge ? <Badge variant={props.badgeVariant}>{props.badgeText}</Badge> : ""}</h6>
                <p className="mb-0 product-displayed-base-price">{getDisplayedBasePrice()}</p>
              <div className="row justify-content-center" style={{ margin: 0, marginTop: "5px", marginBottom: "5px", minWidth: "120px" }}>
                <span className="col-sm-12 col-md-6 " style={{ padding: "0px", marginLeft: "-40px" }}>
                  <button variant="" onClick={DecreaseItem} className="btn-count"> <Icofont icon="minus" /> </button>
                  <input className="count-number-input count-number" type="text" value={numItems} readOnly />
                  <button variant="" onClick={IncrementItem} className="btn-count"> <Icofont icon="icofont-plus" /> </button>
                </span>
                <span className="col-sm-12 col-md-5 product-total" style={{ padding: 0, paddingTop: "6px", fontSize: "15px" }}>
                  Total: <b>${numItems * displayPrice}</b> {_productSize(product)}
                </span>
              </div>
            </Media.Body>

          </Media>
          {openProduct &&
            <Product product={product}
              categories={categories}
              value={numItems}
              isOffer={isOffer}
              onClose={() => setOpenProduct(false)}
              detailColour={detailColour}
              setDisplayTotal={(price, prod) => {
                setDisplayPrice(price)
                //console.log("setDisplayPrice with", price)
                if (prod) setProduct(prod)
              }}
              getDisplayedBasePrice={getDisplayedBasePrice}
              increment={IncrementItem}
              decrement={DecreaseItem}
              hasOrdering={hasOrdering} optionsFirst={optionsFirst} />
          }
        </div>
        :
        <Media onClick={() => {
          setOpenProduct(!openProduct)
        }} >
          {props.image ?
            <Image className={props.imageClass} src={props.image} alt={props.imageAlt} style={{ borderRadius: "50%", marginRight: "16px", height: "70px", width: "70px" }} />
            :
            <div className="mr-3"><Icofont icon="ui-press" className={"text-" + props.badgeVariant + " food-item"} /></div>
          }
          <Media.Body>
            <h6 className="mb-1">{props.title} {props.showBadge ? <Badge variant={props.badgeVariant}>{props.badgeText}</Badge> : ""}</h6>
            <p className="text-gray mb-0">{getDisplayedBasePrice()}</p>
          </Media.Body>
        </Media>
      }

      {/* <Media>
        {props.image ?
          <Image className={"mr-3 rounded-pill " + props.imageClass} src={props.image} alt={props.imageAlt} />
          :
          <div className="mr-3"><Icofont icon="ui-press" className={"text-" + props.badgeVariant + " food-item"} /></div>
        }
        <Media.Body>
          <h6 className="mb-1">{props.title} {props.showBadge ? <Badge variant={props.badgeVariant}>{props.badgeText}</Badge> : ""}</h6>
          <p className="text-gray mb-0">{props.priceUnit}{props.price}</p>
        </Media.Body>
      </Media>

      {openProduct &&

        <Product product={product} onClose={() => setOpenProduct(false)} detailColour={detailColour} />
      } */}

    </div>
  );
}


QuickBite.propTypes = {
  itemClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  image: PropTypes.string,
  imageClass: PropTypes.string,
  showBadge: PropTypes.bool,
  badgeVariant: PropTypes.string,
  badgeText: PropTypes.string,
  price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  qty: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  getValue: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired
};
QuickBite.defaultProps = {
  itemClass: 'gold-members',
  imageAlt: '',
  imageClass: '',
  showBadge: false,
  price: '',
  priceUnit: '$',
  showPromoted: false,
  badgeVariant: 'danger'
}



/* 
class QuickBite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: this.props.qty || 0,
      show: this.props.show || true,
      max:this.props.maxValue || 5,
      min:this.props.minValue || 0
    };
  }

  IncrementItem = () => {
    if(this.state.quantity >= this.state.max) {

    }else {
        this.setState({
            quantity: this.state.quantity + 1 
        });
      this.props.getValue({id:this.props.id,  quantity: (this.state.quantity + 1 )});
    }
  }
  DecreaseItem = () => {
    this.setState({ quantity: this.state.quantity - 1 });
    this.props.getValue({id:this.props.id,  quantity: (this.state.quantity - 1 )});

  //  if(this.state.quantity <= (this.state.min)) {
  //   }else {
  //     this.setState({ quantity: this.state.quantity - 1 });
  //     this.props.getValue({id:this.props.id,  quantity: (this.state.quantity - 1 )});
  //   } 
  // }

  render() {
      return (
        <div className={"p-3 border-bottom QuickBite "+this.props.itemClass}>
       {this.state.quantity===0?
              <span className="float-right"> 
                <Button variant='outline-secondary' onClick={this.IncrementItem} size="sm">ADD</Button>
              </span>
              :
              <span className="count-number float-right">
                 <Button variant="outline-secondary" onClick={this.DecreaseItem} className="btn-sm left dec"> <Icofont icon="minus" /> </Button>
                 <input className="count-number-input" type="text" value={this.state.quantity} readOnly/>
                 <Button variant="outline-secondary" onClick={this.IncrementItem} className="btn-sm right inc"> <Icofont icon="icofont-plus" /> </Button>
              </span>
           }
       <Media>
          {this.props.image?
            <Image className={"mr-3 rounded-pill " +this.props.imageClass} src={this.props.image} alt={this.props.imageAlt} />
            :
            <div className="mr-3"><Icofont icon="ui-press" className={"text-"+this.props.badgeVariant+" food-item"} /></div>
          }
          <Media.Body>
             <h6 className="mb-1">{this.props.title} {this.props.showBadge?<Badge variant={this.props.badgeVariant}>{this.props.badgeText}</Badge>:""}</h6>
             <p className="text-gray mb-0">{this.props.priceUnit}{this.props.price}</p>
          </Media.Body>
       </Media>
    </div>
    );
  }
}


QuickBite.propTypes = {
  itemClass:PropTypes.string,
  title: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  image: PropTypes.string,
  imageClass: PropTypes.string,
  showBadge: PropTypes.bool,
  badgeVariant: PropTypes.string,
  badgeText: PropTypes.string,
  price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  qty: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  getValue: PropTypes.func.isRequired
};
QuickBite.defaultProps = {
  itemClass:'gold-members',
  imageAlt:'',
  imageClass:'',
  showBadge: false,
  price: '',
  priceUnit:'$',
  showPromoted: false,
  badgeVariant: 'danger'
}
 */
export default QuickBite;