import React, {/*  useContext, useEffect, useRef, */ useState  } from 'react';
import { Row, Col, /* Container, Form, Button, Tab, Nav, Image  */} from 'react-bootstrap';

import useTranslations from "../../lib/useTranslations";

import QuickBite from './QuickBite';

const Category = (props) => {

  const { addItemToCart, business, categories, category, index, hideZero, optionsFirst, isSelected,
    isCollapsed,
    removeItem,
    setItemQuantity,
    selectedMenu,
    setSelectedCategory } = props;

  //const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();

  const [isOpen, setCollapsed] = useState(isCollapsed == false)

  const toggleCategoryVisible = () => {
    // if (category) {
    //   console.log("set category to null")
    //   setSelectedCategory(null)
    // } else {
    //   setSelectedCategory(category)
    // }
    setSelectedCategory(category)
    //setCollapsed(!isOpen)
  }

  const _productIsOffer = (product) => {
    // if the product id is in an offer in the location
    if (selectedMenu.Location == null || selectedMenu.Location.Campaigns == null) {
      return false
    }
    let found = selectedMenu.Location.Campaigns.find((offer) => offer.productId == product.id)

    return found
  }

  const isVisible = (p) => {

    if (hideZero && p.managed === true && p.quantity <= 0) {
      // product inventory is managed
      return false
    }

    return p.available === true && p.restricted != true
  }

  let showData = isOpen || isSelected

  const categoryHeaderImage = () => {
    if (category.photo != null && category.photo != "") {
      return <img src={category.photo} width={100} />
    }

    // otherwise use a product photo
    let products = category.Products.filter((p) => p.photo != "")
    if (products.length > 0) {
      // use a random image from the products
      var item = products[Math.floor(Math.random()*products.length)];
      return <img src={item.photo} width={100} />
    }

    return <img src={business.photo} width={100} />
  }

  const titleElement = () => {
    //console.log("titleElement", category, business)
    if (showData) {
      if (category.button != "") {
        console.log("titleElement return button", showData)
        return (
          <h3 className="mt-1 col-md-12 category-button centered" onClick={() => toggleCategoryVisible()}>
            <img src={category.button}  />
          </h3>
        )
  
      }

      console.log("titleElement return div", showData)
  
      return (
        <h5 className="mt-1 col-md-12 category-header">
          <div>

            {categoryHeaderImage()}

            <span>
              {category.name} <br />
              {category.description}
            </span>
          </div>


          <div style={{
            margin: 'auto',
            marginRight: 10,
            marginLeft: 10
          }}>
            <span className="category-expander" onClick={() => toggleCategoryVisible()}>
              {!showData && <i className="fa fa-chevron-left fa-2x" aria-hidden="true" style={{ color: '#999' }}></i>}
              {showData && <i className="fa fa-chevron-down fa-2x" aria-hidden="true" style={{ color: '#999' }}></i>}

            </span>

          </div>

        </h5>
      )
    }

    // If the category has a button photo, then display the button photo. 
    if (category.button != "") {
      console.log("titleElement return button", showData)
      return (
        <h3 className="mt-1 col-md-12 category-button centered" onClick={() => toggleCategoryVisible()}>
          <img src={category.button}  />
        </h3>
      )

    }
    console.log("titleElement return default", showData)
    // Otherwise, display the category image and the category name and description. 
    return (
      <h3 className="mt-1 col-md-12 category-header" onClick={() => toggleCategoryVisible()}>

        <div>
          {categoryHeaderImage()}
        </div>

        <div style={{
          margin: 'auto',
          marginRight: 5,
          marginLeft: 5
        }}>
            {category.name} <br />
            {category.description}
            {/*
           <small className="h6 text-black-50">
          {data.Products.filter((p) => p.available === true).length} {tr('ITEMS')}</small>
        */}
          </div>

        <div style={{
          margin: 'auto',
          marginRight: 10,
          marginLeft: 10
        }}>

          <span className="category-expander" >
            {!showData && <i className="fa fa-chevron-left fa-1x" aria-hidden="true" style={{ color: '#999' }}></i>}
            {showData && <i className="fa fa-chevron-down fa-1x" aria-hidden="true" style={{ color: '#999' }}></i>}
          </span>

        </div>


      </h3>
    )
  }

  //console.log("category", category.name, category)

  return (<Row key={index} id={`category_${category.name}`} className="px-1" >

    {titleElement()}
    {showData &&
      <Col md={12} style={{
        padding: 4
      }}>

        <div id={`category_content_${category.id}`} 
        className="category-content rounded border shadow-sm mb-1">
          {category.Products.filter((p) => isVisible(p)).map((product, i) => {
            let isOffer = _productIsOffer(product)

            return (<QuickBite
              key={i}
              product={product}
              categories={categories}
              id={product.id}
              /* itemClass="menu-list" */
              image={product.photo}
              title={product.name}
              price={product.price}
              hasOrdering={true}
              priceUnit='$'
              isOffer={isOffer}
              getValue={(args) => {
                //console.log("getValue add to cart", product.id, args);
                if (args.quantity === 1) {
                  // make a copy of the object
                  let newItem = Object.assign({}, product);
                  newItem.quantity = 1
                  newItem.menu_id = product.menu_id || selectedMenu.id
                  addItemToCart(newItem, args);
                } else if (args.quantity === 0) {
                  removeItem(product, args);
                } else {
                  setItemQuantity(product, args.quantity);
                }

              }}
              detailColour={'#F1AD3E'}
              optionsFirst={optionsFirst}
            />)
          })}

        </div>

      </Col>
    }
  </Row>)
}

export default Category