import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Container, Form, Button, Tab, Nav, Image } from 'react-bootstrap';

import CheckoutItem from '../components/common/CheckoutItem';
import OwlCarousel from 'react-owl-carousel3';
import Icofont from 'react-icofont';
import jquery from "jquery";

import { getFromApi } from '../lib/api';
import useTranslations from "../lib/useTranslations";
import { CartContext } from '../lib/CartContext';
import { BusinessContext } from '../lib/BusinessContext';
import CardItem from '../components/common/CardItem';
import Category from '../components/common/category';
import CategoryView from '../components/common/category_view'
import Header from '../components/common/Header';

import QuickBite from '../components/common/QuickBite';


const MenuGridItem = (props) => {
  const { menu, business, onSelected } = props;

  useEffect(() => {

  }, [])

  //console.log("business item", business)
  return (
    <div className="item col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 col-xxl-1"
      onClick={onSelected}
      style={{ backgroundColor: "transparent", border: "none", cursor: 'pointer' }}
    >
      <CardItem
        classContainer={'home-product-card'}
        title={menu.Location.name}
        subTitle={[menu.Location.address, menu.Location.city].join(', ')}
        imageAlt='Product'
        image={business.photo}
        imageClass='img-fluid item-img img-card'
        linkUrl='detail'
        offerText=''
        //closesAt={closeIn}
        //time='20–25 min'
        //price='$250 FOR TWO'
        //showPromoted={true}
        promotedVariant='dark'
        favIcoIconColor='text-danger'
      // rating='3.1 (300+)'
      />
    </div>
  )

}

const Detail = (props) => {

  const { cartItems, addItemToCart, clearCart, setItemQuantity, removeItem, pendingOrder, totalItemsCost } = useContext(CartContext);

  const { cityMenus, checkMenuPeriods, locationConfigBool, subscriptionLevel } = useContext(BusinessContext);

  const pricingSection = useRef(null);
  //const { config, getConfig } = useContext(ConfigContext);
  const { tr /* , getTranslations, locale, translations, setLocale */ } = useTranslations();
  const [selectedMenu, setSelectedMenu] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [ordersAvailable, setOrdersAvailable] = useState(false)
  const [hasOrdering, setHasOrdering] = useState(true)
  const [optionsFirst, setOptionsFirst] = useState(false)
  const [openCart, setOpenCart] = useState(false)
  const [availableMenus, setAvailableMenus] = useState(null)
  const [currentCategory, setCurrentCategory] = useState(null)
  const [hideZero, setHideZero] = useState(false)
  const [collapsedCategories, setCollapsedCategories] = useState(false)
  const [searchTerm, setSearchTerm] = useState(null)
  const [productList, setProductList] = useState([])

  const location = useLocation()

  //console.log(location.state) 

  const checkmenu = (menu, hasCredit, business) => {
    let openPeriod = checkMenuPeriods(menu, business)

    setOrdersAvailable(hasCredit && openPeriod.isOpen)
  }

  const _getOptions = (menu, business) => {
    let oba = locationConfigBool(business.Configs, menu.Location.id, "optionsBeforeAddons", false)
    //console.log("oba", oba)
    setOptionsFirst(oba)

    let hzp = locationConfigBool(business.Configs, menu.Location.id, "hideZeroProducts", false)
    setHideZero(hzp)

    let clcats = locationConfigBool(business.Configs, menu.Location.id, "collapsedCategories", true)
    setCollapsedCategories(clcats)

  }

  const _showMenu = (business, hasCredit, menu) => {
    setSelectedMenu(menu)

    checkmenu(menu, hasCredit, business)

    _getOptions(menu, business)

    //console.log("load detail with", location.state.selectedMenu)

    // clear the cart if the items in the cart are from a different business
    /* eslint-disable react-hooks/exhaustive-deps */
    clearCart(menu.Location)
  }

  const _chooseMenu = (business, hasCredit, menus) => {

    if (menus.length === 1) {
      _showMenu(business, hasCredit, menus[0])
      return
    }

    // show an intermediary component to choose the location/menu
    setAvailableMenus(menus)
  }

  useEffect(() => {
    //console.log("useEffect", location.state, props)

    if (props.menu && props.business) {
      //console.log("show menu", props.business, props.menu)
      _addStyleElement(props.business, props.menu)
      _showMenu(props.business, true, props.menu)
    } else if (location.state) {

      if (location.state.Business) {
        // when there are multiple menus for the business we follow this path
        let business = location.state.Business
        let hasCredit = true // businessHasOrdering(business)
        //setHasOrdering(hasCredit)

        let menu
        getFromApi(`/api/v1/menu/business/${business.id}`, {}, (data) => {
          //console.log("get business menus", data.result)

          if (data.result.length === 0) {
            alert(tr("That business does not have an online menu or catalogue"))
          } else if (data.result.length === 1) {
            menu = data.result[0]
            _addStyleElement(props.business, props.menu)
            _showMenu(business, hasCredit, menu)
          } else {

            // get the menus in the same city
            let menus = cityMenus(business, data.result)

            // find the default domicilio menu or a menu for the current city                  
            let deliveryMenus = menus.filter((m) => m.deliveries === true)

            if (deliveryMenus.length === 1) {
              menu = deliveryMenus[0]
              _addStyleElement(props.business, props.menu)
              _showMenu(business, hasCredit, menu)
            } else if (deliveryMenus.length > 1) {
              _chooseMenu(business, hasCredit, deliveryMenus)
            } else {
              _chooseMenu(business, hasCredit, menus)
            }
          }

        }, (error) => {
          console.log('get business data failed', error)
        })
      } else if (location.state.selectedMenu) {
        let mData = location.state.selectedMenu
        setSelectedMenu(mData)

        let business = mData.Business
        _addStyleElement(mData.Business, mData)
        //business.subscriptionLevel = subscriptionLevel(business)
        //let hasCredit = true // businessHasOrdering(business)
        if (business.type == "Products") {
          setHasOrdering(true)
        } else {
          setHasOrdering(mData.orders)
        }

        _getOptions(mData, business)

        //console.log("hasOrdering", hasCredit, business)
        checkmenu(mData, true, business)
      }
    } else {
      window.location.href = "/"
    }

  }, [pendingOrder, cartItems]);


  function _getOffer() {
    if (selectedMenu && selectedMenu.Offers && selectedMenu.Offers.length > 0) {
      let offer = selectedMenu.Offers.find(offer => offer.isActive === true);

      return (
        <div className="bg-white rounded shadow-sm text-white mb-4 p-4 clearfix restaurant-detailed-earn-pts card-icon-overlap">
          <Image fluid className="float-left mr-3" src="/img/earn-score-icon.png" />
          <h6 className="pt-0 text-primary mb-1 font-weight-bold">{tr('OFFER')}</h6>
          <p className="mb-0">{offer.name}<span className="text-danger font-weight-bold">{offer.saving}</span></p>
          <div className="icon-overlap">
            <Icofont icon="sale-discount" />
          </div>
        </div>
      )
    }
  }

  //console.log("selectedMenu", selectedMenu)

  const getHeader = (menuData) => {

    return assetsRow(menuData.Business, menuData)

  }

  const assetsRow = (business, menu) => {

    const options = {
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 2,
        },
        1200: {
          items: 3,
        },
      },
      items: business.Assets.length,
      lazyLoad: true,
      pagination: "false",
      loop: true,
      center: true,
      dots: true,
      loop: true,
      margin: 10,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
      nav: false,
      navText: ["<i class='icofont-thin-left'></i>", "<i class='icofont-thin-right'></i>"]
    }

    if (business.Assets && business.Assets.length > 1) {
      // return a carousel with the assets
      return (
        <div className="row business-assets-carousel">
          <div className="col-12 centered">
            <OwlCarousel nav loop {...options} className="owl-theme owl-carousel-five offers-interested-carousel">
              {business.Assets.map((a, index) => {
                return (
                  <div className="item" key={`carouselImg_${index}`}>
                    <img src={a.url} style={{
                      maxHeight: 200,
                      minHeight: 200,
                      maxWidth: 600,
                    }} />
                  </div>
                )
              })

              }

            </OwlCarousel>
          </div>
        </div>
      )
    }

    // get random 5 products with photos
    let products = []

    menu.Category.Categories.forEach((cat) => {
      let prods = cat.Products.filter((p) => p.photo != "" && p.photo != business.photo)
      if (prods.length > 0) {
        products.push(prods)
      }
    })

    products = products.flat()

    if (products.length > 10) {
      products = products.slice(0, 10)
    }
    //console.log("products", products)

    if (products.length > 0) {
      // return the asset image
      return (
        <div className="row product-carousel">
          <div className="col-12 centered">
            <OwlCarousel nav loop {...options} className="owl-theme owl-carousel-five offers-interested-carousel">
              {products.map((a, index) => {
                return (
                  <div className="item" key={`carouselImg_${index}`}>
                    <img src={a.photo} style={{
                      maxHeight: 200,
                      minHeight: 200,
                      maxWidth: 600,
                      //objectFit: 'cover'
                    }} />
                  </div>
                )
              })

              }

            </OwlCarousel>
          </div>
        </div>
      )
    } else if (business.banner && business.banner != "") {
      // return the business banner
      return (
        <div className="row business-banner-row">
          <div className="col centered"><img src={business.banner} style={{
            maxHeight: 200,
            minHeight: 100,
            maxWidth: 400,
            objectFit: 'cover'
          }} /></div>
        </div>
      )
    }

    return <></>

  }

  const styleForCategory = (category) => {
    if (category === currentCategory) {
      return {
        border: 'none',
        borderBottom: '3px solid #F1AD3E'
      }
    }
    return {}
  }

  const _styleForCategoryScrollMenu = () => {
    if (currentCategory) {
      return {
        curser: 'pointer',
        position: 'sticky',
        top: '0%',
        zIndex: 10
      }
    }
    return {
      curser: 'pointer'
    }
  }

  const matchesterm = (product, term) => {
    return product.name.toLowerCase().includes(term) || product.description.toLowerCase().includes(term)
  }

  const searchProducts = (term) => {
    // search all categories for products with matching name or description
    if (term && term.length > 2) {
      let list = []

      categories.forEach((category, index) => {
        let products = category.Products.filter((p) => matchesterm(p, term))
        list = list.concat(products)
      })
      //console.log("searchProducts result", list)

      setProductList(list)
    }
  }

  const _prefixedColour = (colour) => {
    if (colour.indexOf('#') == 0) {
      return colour
    }
    return "#" + colour
  }

  function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }
  function invertColor(hex, bw) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      // https://stackoverflow.com/a/3943023/112731
      return (r * 0.299 + g * 0.587 + b * 0.114) > 186
        ? '#000000'
        : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
  }

  function styleFromColour(colour) {
    let style = {
      backgroundColor: _prefixedColour(colour),
      color: invertColor(colour, true)
    }
    return `
    body {
      background-color: ${style.backgroundColor};
      color: ${style.color};
    }
    #root {
      background-color: ${style.backgroundColor};
      color: ${style.color};
    }
    .menu-detail-container {
      background-color: ${style.backgroundColor};
      color: ${style.color};
    } 
    .menu-detail-container .social-button i {
      color: ${style.color};
    }
    .search_bar_row a {
      color: ${style.color};
    }
    `;
  }

  const _addStyleElement = (business, menu) => {

    let css = document.createElement('style');

    css.innerHTML = `
    body {
      background-color: #000000;
      color: #ffffff;
        }
      #root {
        background-color: #000000;
        color: #ffffff;
      }
      .menu-detail-container {
          background-color: #000000;
          color: #ffffff;
        } 
        .menu-detail-container .social-button i {
          color: white;
        }
        .search_bar_row a {
          color: white;
        }
      `;

    if (menu.colour && menu.colour != "") {
      css.innerHTML =  styleFromColour(menu.colour)
    } else if (business.style && business.style != "") {
      css.innerHTML = business.style
    } else if (business.colour && business.colour != "") {
      css.innerHTML =  styleFromColour(business.colour)
    }

    console.log("style", css.innerHTML)

    document.head.appendChild(css);

  }

  if (!selectedMenu && availableMenus && availableMenus.length > 1) {
    // show a menu chooser

    return <>
      {getHeader(availableMenus[0])}

      <section className="offer-dedicated-body pt-2 pb-2 mt-4 mb-4">
        <Container>
          <Row>
            <Col md={12}>
              <div
                // breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid padding-home-product row centrar"
              // columnClassName="my-masonry-grid_column "

              >
                {availableMenus.map((menu, index) => (

                  <MenuGridItem menu={menu} business={menu.Business} key={index} onSelected={() => {
                    _showMenu(menu.Business, true, menu)
                  }} />

                ))}
              </div>

              <h4>Selecione tú sede más cercana</h4>
            </Col>


          </Row>
        </Container>
      </section>

    </>

  }

  if (!selectedMenu) {

    return <></>
  }

  let shareURL = {};
  let checkoutLocation = {}

  //console.log("render with", selectedMenu)
  shareURL = {
    title: selectedMenu.Business.Name,
    text: 'Visita este enlace para grandes ofertas',
    url: `https://zono.cc?bid=${selectedMenu.business_id}&lid=${selectedMenu.location_id}&mid=${selectedMenu.id} `
  };

  checkoutLocation = {
    pathname: '/checkout',
    state: { selectedMenu: selectedMenu, items: cartItems }
  }

  let categories = selectedMenu.Category.Categories.filter((c) => c.enabled === true).sort((a, b) => a.sequence - b.sequence)


  function ContentPanel(props) {

    return <Tab.Container defaultActiveKey="first">
      <section className="offer-dedicated-nav bg-white border-top-0 shadow-sm">

      </section>
      <section className="offer-dedicated-body pt-2 pb-2 mb-4">
        <Container>
          <Row>
            <div className="col-md-8 px-0" >
              <div className="offer-dedicated-body-left">
                <Tab.Content className='h-100'>
                  <Tab.Pane eventKey="first">

                    {(hasOrdering === false || ordersAvailable === false) &&
                      <Row style={{ margin: 5 }}>
                        <Col md={12} >
                          <h5>
                            {tr("This business cannot take orders at the moment, please check back later")}
                          </h5>
                        </Col>
                      </Row>

                    }
                    {props.children}

                  </Tab.Pane>

                </Tab.Content>
              </div>
            </div>
            <Col md={4} className="hide-cart px-0"
            // onClick={() => setOpenCart(!openCart)/*pricingSection.current?.scrollIntoView({ behavior: 'smooth' })*/}
            // style={{ cursor: 'pointer' }}
            >
              {openCart
                ?
                <div className="generator-bg rounded shadow-sm osahan-cart-item sticky-position-up px-1"
                  ref={pricingSection}
                  style={{ maxHeight: "410px" }}>
                  <div className='row'
                    onClick={() => setOpenCart(!openCart)}
                    style={{ cursor: 'pointer' }}>
                    <div className='col-7' style={{ paddingTop: "8px" }}>
                      <h5 className="mb-1 text-white">{tr("Cart")}
                      </h5>
                      <span className=" text-white">{cartItems.length} {tr("Items")}</span>
                    </div>
                    <div className='col-5' style={{ color: "white", display: "flex", justifyContent: "center" }}>
                      <div className='row'>
                        <div className='col-12 ' style={{ color: "white", padding: 0, display: "flex", justifyContent: "center" }}>
                          <i className="fa fa-arrow-down fa-lg" aria-hidden="true"></i>
                        </div>
                        <div className='col-12 ' style={{ color: "white", display: "flex", justifyContent: "center" }}>
                          <i className='fa fa-shopping-cart fa-3x'></i>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="bg-white rounded shadow-sm mb-2" style={{ maxHeight: "130px", overflow: "scroll" }}>
                    {cartItems.map((item, index) => {
                      return <CheckoutItem
                        key={'cart_item_' + index}
                        itemName={item.name}
                        cartItem={item}
                        price={item.price}
                        priceUnit="$"
                        id={item.id}
                        quantity={item.quantity}
                        show={true}
                        minValue={0}
                        maxValue={7}
                        setValue={(args) => {
                          //console.log("checkout item", args);
                          if (args.quantity === 0) {
                            removeItem(item, args);
                          } else {
                            setItemQuantity(item, args.quantity);
                          }
                        }}
                      />
                    }
                    )}

                  </div>
                  <div className="mb-2 bg-white rounded p-2 clearfix">
                    <Image fluid className="float-left" src="/img/wallet-icon.png" />
                    <h6 className="font-weight-bold text-right mb-2">{tr('Subtotal')} : <span className="text-danger">{totalItemsCost()}</span></h6>
                    <p className="seven-color mb-1 text-right">{tr("Extra charges may apply")}</p>
                    {/* <p className="text-black mb-0 text-right hidden">You have saved $955 on the bill</p> */}
                  </div>
                  <Link to={checkoutLocation} className="btn btn-success btn-block btn-lg">{tr('Next')}
                    <Icofont icon="long-arrow-right" /></Link>
                  <div className="pt-2"></div>
                  {/*  <div className="alert alert-success hidden" role="alert">
          You have saved <strong>$1,884</strong> on the bill
        </div> */}
                  <div className="pt-2"></div>
                </div>
                :
                <div className={`${cartItems.length === 0 ? 'generator-bg' : 'cart-color'} rounded shadow-sm  osahan-cart-item`}
                  onClick={() => setOpenCart(!openCart)}
                  style={{ maxHeight: "80px", cursor: 'pointer', padding: "12px", paddingLeft: "24px" }}>
                  <div className='row'>
                    <div className='col-7' style={{ paddingTop: "8px" }}>
                      <h5 className="mb-1 text-white">{tr("Cart")}
                      </h5>
                      <span className=" text-white">{cartItems.length} {tr("Items")}</span>
                    </div>

                    <div className='col-5' style={{ color: "white", display: "flex", justifyContent: "center" }}>
                      <div className='row'>
                        <div className='col-12 ' style={{ color: "white", padding: 0, display: "flex", justifyContent: "center" }}>
                          <i className="fa fa-arrow-up fa-lg" aria-hidden="true"></i>
                        </div>
                        <div className='col-12 ' style={{ color: "white", display: "flex", justifyContent: "center" }}>
                          <i className='fa fa-shopping-cart fa-3x'></i>
                        </div>
                      </div>

                    </div>

                    {/* <div className='col-4 ' style={{ color: "white",display:"flex", justifyContent:"right" }}>
                <i className='fa fa-shopping-cart fa-3x'></i>
              </div>
              <div className='col-2 ' style={{ color: "white", padding:0}}>
                <i className="fa fa-arrow-up fa-2x" aria-hidden="true"></i>
              </div> */}
                  </div>
                </div>
              }

            </Col>
            <Col md={4} className="">

              {_getOffer()}

              <div className="show-text generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item sticky-position-up " ref={pricingSection}>
                <div className='' >
                  <h5 className="mb-1 text-white">{tr("Your Order")}
                  </h5>
                  <p className="mb-4 text-white">{cartItems.length} {tr("Items")}</p>
                </div>
                <div className="bg-white rounded shadow-sm mb-2" >
                  {cartItems.map((item, index) => {
                    //console.log("checkout item", item)
                    return <CheckoutItem
                      key={'cart_item_' + index}
                      itemName={item.name}
                      cartItem={item}
                      price={item.chosenPrice || item.price}
                      priceUnit="$"
                      id={item.id}
                      quantity={item.quantity}
                      show={true}
                      minValue={0}
                      maxValue={7}
                      setValue={(args) => {
                        //console.log("checkout item", args);
                        if (args.quantity === 0) {
                          removeItem(item, args);
                        } else {
                          setItemQuantity(item, args.quantity);
                        }
                      }}
                    />
                  }
                  )}

                </div>
                <div className="mb-2 bg-white rounded p-2 clearfix">
                  <Image fluid className="float-left" src="/img/wallet-icon.png" />
                  <h6 className="font-weight-bold text-right mb-2">{tr('Subtotal')} : <span className="text-danger">{totalItemsCost()}</span></h6>
                  <p className="seven-color mb-1 text-right">{tr("Extra charges may apply")}</p>
                  {/* <p className="text-black mb-0 text-right hidden">You have saved $955 on the bill</p> */}
                </div>
                <Link to={checkoutLocation} className="btn btn-success btn-block btn-lg">{tr('Next')}
                  <Icofont icon="long-arrow-right" /></Link>
                <div className="pt-2"></div>
                {/*  <div className="alert alert-success hidden" role="alert">
            You have saved <strong>$1,884</strong> on the bill
          </div> */}
                <div className="pt-2"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

    </Tab.Container>
  }

  const _productIsOffer = (product) => {
    // if the product id is in an offer in the location
    if (!selectedMenu || selectedMenu.Location == null || selectedMenu.Location.Campaigns == null) {
      return false
    }
    let found = selectedMenu.Location.Campaigns.find((offer) => offer.productId == product.id)

    return found
  }

  console.log("detail with menu", selectedMenu, selectedCategory, currentCategory, collapsedCategories)

  const _getContent = () => {

    if (productList.length > 0) {
      // show a list of products
      return (
        <ContentPanel>
          {searchBarRow(selectedMenu.Location)}
          {productList.map((product, i) => {
            let isOffer = _productIsOffer(product)

            return (<QuickBite
              key={i}
              product={product}
              categories={categories}
              id={product.id}
              /* itemClass="menu-list" */
              image={product.photo}
              title={product.name}
              price={product.price}
              hasOrdering={true}
              priceUnit='$'
              isOffer={isOffer}
              getValue={(args) => {
                //console.log("getValue add to cart", product.id, args);
                if (args.quantity === 1) {
                  // make a copy of the object
                  let newItem = Object.assign({}, product);
                  newItem.quantity = 1
                  newItem.menu_id = product.menu_id || selectedMenu.id
                  addItemToCart(newItem, args);
                } else if (args.quantity === 0) {
                  removeItem(product, args);
                } else {
                  setItemQuantity(product, args.quantity);
                }

              }}
              detailColour={'#F1AD3E'}
              optionsFirst={optionsFirst}
            />)
          })}
        </ContentPanel>

      )
    }
    if (!selectedCategory) {
      return <div>
        {getHeader(selectedMenu)}
        {searchBarRow(selectedMenu.Location)}

        <ContentPanel>

          {categories.map((category, index) => {
            return (
              <Category
                key={`category_${category.name}`}
                addItemToCart={addItemToCart}
                category={category} index={index}
                categories={categories}
                hideZero={hideZero}
                isCollapsed={collapsedCategories}
                isSelected={currentCategory && currentCategory.id == category.id}
                optionsFirst={optionsFirst}
                removeItem={removeItem}
                setItemQuantity={setItemQuantity}
                selectedMenu={selectedMenu}
                business={selectedMenu.Business}
                setSelectedCategory={setSelectedCategory}
              />
            )

          })

          }

        </ContentPanel>
      </div>
    }

    // display the category contents
    return (
      <ContentPanel>
        {searchBarRow(selectedMenu.Location)}
        <CategoryView
          addItemToCart={addItemToCart}
          category={selectedCategory} index={1}
          categories={categories}
          hideZero={hideZero}
          isCollapsed={false}
          isSelected={true}
          optionsFirst={optionsFirst}
          removeItem={removeItem}
          setItemQuantity={setItemQuantity}
          selectedMenu={selectedMenu}
          business={selectedMenu.Business}
          setSelectedCategory={(value) => {
            //console.log("set category to ", value)
            setSelectedCategory(value)
          }}
          searchTerm={searchTerm}
        />
      </ContentPanel>

    )
  }

  const doSearch = (term) => {

    if (!term || term.length < 3) {
      setSearchTerm(term)
      if (productList.length > 0) {
        // reset the product list to empty list
        setProductList([])
      }
      return
    }

    term = term.toLowerCase()

    setSearchTerm(term)

    console.log("search", term)

    // if a category is selected search the category
    if (selectedCategory) {
      let products = selectedCategory.Products.filter((p) => matchesterm(p, term))

      //console.log("set matching products", products)
      setProductList(products)

    } else {
      // otherwise find the products that match and go to a product list page
      searchProducts(term)
    }
  }

  const searchBarRow = (location) => {
    let mapsUrl = `https://maps.google.com/?ll=${location.latlng}`
    let whatsappUrl = `https://wa.me/?call=${location.phone}`
    let callUrl = `tel:${location.phone}`

    return <div className="search_bar_row">
      <a href={mapsUrl} target="_blank">
        <i className='fa fa-location-dot fa-2x' style={{
          paddingLeft: 5,
          verticalAlign: 'bottom',

        }}
        ></i>
      </a>

      <a href={whatsappUrl} target="_blank">
      <i className='fa fa-whatsapp fa-2x' style={{
        paddingLeft: 5,
        verticalAlign: 'bottom',

      }}
      ></i>
      </a>

      <a href={callUrl} target="_blank">

      <i className='fa fa-phone fa-2x' style={{
        paddingLeft: 5,
        verticalAlign: 'bottom',

      }} 

      ></i>

      </a>


      {searchBar()}

    </div>
  }

  const searchBar = () => {

    const $ = jquery;

    const _searchIcon = () => {
      if (productList.length > 0) {
        return  <i className='fa fa-close ' style={{
          paddingLeft: 5,
          verticalAlign: 'bottom',
          fontSize: 20
  
        }}
        onClick={() => {
          doSearch("")
          $("#search_input_box").val("")
        }}
  
        ></i>
      }
      return  <i className='fa fa-search ' style={{
        paddingLeft: 5,
        verticalAlign: 'bottom',
        fontSize: 20

      }}

      ></i>

      
     
    }

    return (

      <div className="search_bar_element">

        <input name="search box"
          id="search_input_box"
          size="10"
          defaultValue={searchTerm}

          onChange={(evt) => {
            if (evt.target.value.length > 2) {
              doSearch(evt.target.value)
            }
          }

          } />

        {_searchIcon()}

      </div>
    )
  }

  return (
    <div className="menu-detail-container" >

      <Header business={selectedMenu.Business} menu={selectedMenu}

        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />

      <div >
        {_getContent()}
      </div>

    </div>
  );

}


export default Detail;
/* 

                    <div className="row justify-content-center category-scroll-menu"
                      style={_styleForCategoryScrollMenu()}>
                      <div className="horizontal-scrollmenu">
                        {categories.map((category, index) => {
                          return (
                            <div className="category-scroll-name"
                              key={`category${category.id}`}
                              onClick={() => {
                                setCurrentCategory(category)
                                document.getElementById(`category_${category.id}`).scrollIntoView();
                              }}
                              style={styleForCategory(category)}
                            >
                              {category.name}
                            </div>
                          )

                        })}
                      </div>
                    </div>
*/
/*
   const _productIsOffer = (product) => {
     // if the product id is in an offer in the location
     if (selectedMenu.Location == null || selectedMenu.Location.Campaigns == null) {
       return false
     }
     let found = selectedMenu.Location.Campaigns.find((offer) => offer.productId == product.id)
 
     return found
   }
 
   const isVisible = (p) => {
 
     if (hideZero && p.managed === true && p.quantity <= 0 ) {
       // product inventory is managed
       return false
     }
 
     return p.available === true && p.restricted != true
   } 
   

 const _categorySelector = () => {
   return (
     <div className="row justify-content-center category-selector "
     >
       <select
         onChange={(event) => {
           let catName = event.target.value

           //console.log("category", catName)
           setCurrentCategory(catName)
           document.getElementById(`category_${catName}`).scrollIntoView();
         }}

       >
         {categories.map((category, index) => {
           if (category === currentCategory) {
             return (
               <option value={category.name}
                 key={`category${category.id}`}
                 selected
               >
                 {category.name}
               </option>
             )
           }


           return (
             <option value={category.name}
               key={`category${category.id}`}

             >
               {category.name}
             </option>
           )

         })}
       </select>
     </div>
   )
 }

   */