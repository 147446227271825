import React, { createContext, useState, useEffect } from 'react';
import { postToApi } from './api';
import { uuidv4 } from './utils'

// import { isEmpty } from 'lodash';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [items, setItems] = useState([]);
  //const [order, setOrder] = useState(null)
  const [pendingOrder, setPendingOrder] = useState(null)
  const [dateOfBirth, setDateOfBirth] = useState(null)
  const [ageConfirmed, setAgeConfirmed] = useState(false)

  const OrderStatus = {

    Accepted: "accepted",

    Change: "change",
    Changed: "changed",
    ConfirmCosts: "confirmcosts",
    ConfirmRequired: "confirmrequired",
    Confirmed: "confirmed",

    DeliveryCost: "deliverycost",
    DeliveryAccepted: "deliveryaccepted",
    DeliveryConfirmed: "deliveryconfirmed",
    Dispatched: "dispatched",
    Delivered: "delivered",

    Exported: "exported",  // order has been exported

    New: "new",
    OpenBill: "openbill",  // table order accumulating line items
    PaymentRequired: "paymentrequired",
    Paid: "paid",
    Preparing: "preparing",
    Ready: "ready",
    Received: "received",
    Rejected: "rejected",
    AutoRejected: "auto_rejected",
  }

  const MessageSubject = {
    ConfirmRequired: "ConfirmRequired", // to customer -> order change confirmation required
    ConnectWebApp: "ConnectWebApp", // to web app /connect

    DeliveryAccepted: "DeliveryAccepted",
    DeliveryCost: 'DeliveryCost',
    DriverLocation: "DriverLocation",

    ExtraCosts: "ExtraCosts", // to customer -> extra costs need accepting by customer

    OrderAccepted: "OrderAccepted", // to customer -> order accepted by merchant   
    OrderChange: "OrderChange", // to customer -> order change required
    OrderChanged: "OrderChanged", // order has been changed
    OrderDelivered: "OrderDelivered", // to customer -> order dispatched
    OrderDispatched: "OrderDispatched", // to customer -> order dispatched
    OrderEnRoute: "OrderEnRoute", // to customer -> order en route
    OrderInPrep: "OrderInPrep", // to customer -> order in preparation
    OrderPayment: "OrderPayment", // to customer -> payment required
    OrderPaid: "OrderPaid", // customer has paid for the order

    OrderReceived: "OrderReceived", // to customer -> order received by merchant
    OrderRejected: "OrderRejected", // to customer -> order rejected by merchant
    OrderReady: "OrderReady", // to customer -> order prepared
    OrderReadyIn: "OrderReadyIn", // to customer _> order ready in some time

    PaymentLink: "PaymentLink",
    PaymentQRcode: "PaymentQRcode",
    ReconfirmOrder: "ReconfirmOrder", // to merchant -> extra costs accepted by customer
    SendOrder: "SendOrder", // to merchant -> order submitted by customer
  }

  useEffect(() => {
    let list = localStorage.getItem('cartItems')
    let po = localStorage.getItem('pendingOrder')

    if (list) {
      let newItems = JSON.parse(list)
      setItems(newItems);
    }

    if (po) {
      let order = JSON.parse(po)
      //console.log("pending order", order)

      setPendingOrder(order)

      // if the order is recent
      let when = Date.parse(order.tstamp)
      let now = new Date()

      /*   if (when > (now.getTime() - (30 * 60 * 1000))) {
          setPendingOrder(order)
  
        } else {
          
          clearCart()
          setPendingOrder(null)
  
        console.log("pending order is old", order)
        } */
    }

  }, []);

  const _updateItems = (list) => {
    setItems(list);
  }

  const addOrderHistory = (order) => {
    let list = []
    let data = localStorage.getItem("orderHistory")

    if (data) {
      list = JSON.parse(data)
    }

    // ensure this order number is not already in the list
    if (list.find((o) => o.id === order.id)) {
      return
    }

    list = [order, ...list]

    //console.log("add order history", list)

    localStorage.setItem('orderHistory', JSON.stringify(list))
  }

  const addItemToCart = (item) => {

    let newItems = [...items, item];

    //console.log("set cart items", newItems)
    _updateItems(newItems);
  }

  const removeItem = (item) => {
    let newItems = items.filter(x => x.uuid !== item.uuid);
    _updateItems(newItems);
  }

  const setItemQuantity = (product, quantity) => {

    // find the item for product.id 
    let item = items.find(x => x.uuid === product.uuid);
    item.quantity = quantity;

    let newItems = items.slice()

    // console.log("setItemQuantity cart items", newItems)
    _updateItems(newItems);
  }

  const incrementItem = (item) => {
    item.quantity++;

    let newItems = [...items];  // items.slice()

    _updateItems(newItems);
  }

  const decrementItem = (item) => {
    // if the item quantity is 1 remove the item from the cart
    if (item.quantity === 1) {
      removeItem(item);
    } else {
      item.quantity--;
    }
    let newItems = items.slice()
    _updateItems(newItems);
  }

  const clearCart = (location) => {
    //console.log("clear cart with", location)

    if (location) {
      //console.log("clearing cart compare location", items.length)
      if (items.length > 0) {
        // clear the cart if a different location
        if (items[0].location_id !== location.id) {
          //console.log("clearing cart different location")
          _updateItems([])
        } else {
          //console.log("preserving cart same location")
        }

      }
    } else {
      //console.log("clearing cart no location")
      _updateItems([])
    }

  }

  const totalItemsCost = (itemsToSum) => {
    let total = 0;

    itemsToSum = itemsToSum || items

    itemsToSum.forEach(item => {
      total += (getItemTotalCost(item) * item.quantity);
    })

    return total;
  }

  const getComboCost = (product) => {
    let price = 0

    // get the cost of each combo element for the chosen size
    // if no size is chosen then use the default size/price

    if (product.price > 0) {
      return product.price
    }

    product.Combos.forEach((ci) => {

      let cp = 0

      //price += comboProductPriceForSize(ci, product.chosenSize, ci.multiplier)

      if (ci.selectedItem && product.chosenSize) {
        // use the price for the chosen size
        let size = ci.selectedItem.Sizes.find((s) => s.size === product.chosenSize)

        if (size) {
          //console.log("set price", product.chosenSize, size, ci)
          cp = (size.price * ci.multiplier)
        } else {
          cp = (ci.selectedItem.price * ci.multiplier)
        }
      } else if (ci.selectedItem) {
        cp = (ci.selectedItem.price * ci.multiplier)
      }

      price += (cp - (cp % 50))
    })

    if (product.multiplier > 0 && product.multiplier != 1) {

      // apply the product multiplier
      let final = price * product.multiplier

      // round down to 50
      return final - (final % 50)
    }
    return price

  }

  const comboProductPriceForSize = (product, chosenSize, multiplier) => {
    let size
    let price = 0

    if (chosenSize && chosenSize !== "") {
      size = product.Sizes.find((s) => s.size === chosenSize)
    }

    //console.log("_comboProductPriceForSize", chosenSize, size, product)
    if (size) {
      price = size.price
    } else {
      price = product.price
    }

    // apply the product multiplier
    let final = price * multiplier

    // round down to 50
    return final - (final % 50)
  }

  const getProductCost = (product) => {

    let price = product.price

    if (product.type === 'Combo' && price == 0) {
      price = getComboCost(product)
    } else if (product.chosenPrice) {
      price = product.chosenPrice
    }

    return price
  }

  const getItemTotalCost = (product) => {

    let price = product.price

    if (product.type === 'Combo' && price == 0) {
      price = getComboCost(product)
    } else if (product.chosenPrice) {
      price = product.chosenPrice
    }

    let selectedOptions = product.selectedOptions || []
    selectedOptions.forEach(o => price = price + o.price)

    // add the price for each selected addon
    let selectedAddons = product.selectedAddons || []

    selectedAddons.forEach((a) => price = price + (a.price * a.quantity))

    // determined price
    //console.log("product price", price)

    return price
  }

  const clearOrder = () => {
    setPendingOrder(null)
  }

  const submitOrder = (menuData, deliverType, deliveryData, paymentMethod, items, packingCost, onSuccess, onError) => {
    let lineItems = []
    let total = totalItemsCost(items)
    let position = deliveryData.position || {}

    clearOrder()

    items.forEach((p) => {
      lineItems.push(makeLineItemProduct(p))
    })

    let guestId = `guest:${menuData.id}:${uuidv4()}`
    // save the guest ID so the order progress can listen to the 
    localStorage.setItem('guestId', guestId)

    let order = {
      //id: 0,
      businessId: menuData.Business.id,
      locationId: menuData.location_id,
      name: deliveryData.name,
      phone: deliveryData.phone,
      email: deliveryData.email,
      deliverType: deliverType,
      tableNum: deliveryData.tableNum ? parseInt(deliveryData.tableNum) : 0,
      status: 'new',
      itemscost: total,
      packingcost: packingCost || 0,
      totalcost: total,
      currency: 'COP',
      paymentMethod: paymentMethod,
      uuid: guestId,
      items: lineItems,
    }

    if (deliverType === 'delivery') {
      let addr = `${deliveryData.address}, ${deliveryData.city}`

      if (deliveryData.barrio && deliveryData.barrio != "") {
        addr = `${deliveryData.address}, ${deliveryData.barrio}, ${deliveryData.city}`
      }
      Object.assign(order, {
        instruction: deliveryData.instructions,
        destination: addr,
        latitude: position.latitude,
        longitude: position.longitude
      })
    } else if (deliverType === 'table') {
      Object.assign(order, {
        destination: deliveryData.destination,
      })
    }

    doSendOrder(order, menuData, deliveryData, onSuccess, (error) => {
      // try again after a short delay
      console.log("error", error)
      setTimeout(() => {
        console.log("trying again")
        doSendOrder(order, menuData, deliveryData, onSuccess, (error) => {
          // try again after a short delay
          console.log("error", error)
          setTimeout(() => {
            console.log("trying again")
            doSendOrder(order, menuData, deliveryData, onSuccess, (error) => {
              // try again after a short delay
              console.log("error", error)
              setTimeout(() => {
                console.log("trying again")
                doSendOrder(order, menuData, deliveryData, onSuccess, (error) => {
                  // try again after a short delay
                  console.log("error", error)
                  setTimeout(() => {
                    console.log("trying again")
                    doSendOrder(order, menuData, deliveryData, onSuccess, (error) => {
                      // try again after a short delay
                      console.log("error", error)
                      onError(error)
                    })
                  }, 2500)
                })
              }, 2000)
            })
          }, 1500)
        })
      }, 1000)
    })
  }

  const doSendOrder = (order, menuData, deliveryData, onSuccess, onError) => {
    // submit the order to the API
    console.log("submit the order to the API", order)

    postToApi("/api/v1/order", { id: 0 /* this is a dummy user object hence a userID of 0 */ }, order,
      (response) => {
        console.log("result", response)

        order = response.result

        if (order.status !== 'new') {
          onError("Hubo un problema al enviar su pedido")
          return
        }

        // create the payload with the result from create order so the line items have the order ID
        let payload = {
          status: "new",
          'orderId': order.id,
          message: "",
          content: JSON.stringify(order)
        }

        //order.items = items

        // add the business and location from the menu data
        order.Business = menuData.Business
        order.Location = menuData.Location
        order.deliveryData = deliveryData
        order.menuId = menuData.id

        // use the items from the cart which can have more data 
        order.items = items

        localStorage.setItem('pendingOrder', JSON.stringify(order))
        // set the pending order for post order info
        setPendingOrder(order)

        // clear the items so the same order cannot be submitted again
        clearCart()

        // broadcast the order to the merchant
        let channel = `location:${menuData.location_id}`


        let user = {
          id: 0,
          email: order.uuid + "@zonomenu.cc",
          token: order.uuid
        }

        let map = {
          event: "message",
          subject: 'SendOrder',
          channel: channel,
          payload: JSON.stringify(payload),
          uuid: order.uuid
        }
        let path = `/api/v1/order/publish/${order.id}`

        postToApi(path, user, map, (data) => {
          //console.log("order notified to API", data)

        }, (error) => {
          console.log("Error notifying order to API", error)

        })

        onSuccess(order)

      }, (error) => {
        console.log("error adding order", error)
        onError("Hubo un problema al enviar su pedido")
      })
  }

  const hasAlcohol = () => {
    let result = false

    items.forEach((p) => {
      if (p.Alcohol === true) result = true
    })

    //console.log("hasAlcohol", result)
    return result
  }


  const clearCartData = (menuId) => {

    if (menuId != undefined) {
      let newItems = items.filter((i) => i.menu_id === menuId)
      _updateItems(newItems);
    } else {

      localStorage.removeItem("cartItems")
      //localStorage.removeItem("paymentMethod")
      //localStorage.removeItem("deliveryAddress")
      clearOrder()
      clearCart()
    }
  }

  return (
    <CartContext.Provider
      value={{
        hasAlcohol: hasAlcohol,
        dateOfBirth: dateOfBirth,
        setDateOfBirth: setDateOfBirth,
        ageConfirmed: ageConfirmed,
        setAgeConfirmed: setAgeConfirmed,
        cartItems: items,
        setCartItems: setItems,
        pendingOrder, setPendingOrder,
        addItemToCart,
        addOrderHistory,
        incrementItem,
        decrementItem,
        removeItem,
        clearCart,
        clearOrder,
        clearCartData,
        comboProductPriceForSize,
        getProductCost,
        getItemTotalCost,
        totalItemsCost,
        setItemQuantity,
        submitOrder,
        // constants
        OrderStatus,
        MessageSubject
      }}
    >
      {children}
    </CartContext.Provider>
  )


  function _addonsForProduct(product) {
    if (!product.selectedAddons || product.selectedAddons.length === 0) return []

    // add any addons 
    let addons = product.selectedAddons

    return addons.map((a) => {
      return { id: a.id, name: a.name, price: a.price, quantity: a.quantity, sku: a.sku }
    })
  }

  function _optionsForProduct(product) {
    if (!product.selectedOptions || product.selectedOptions.length === 0) return []

    // add any addons 
    let options = product.selectedOptions

    return options.map((a) => {
      return { id: a.id, group_name: a.group_name, name: a.name, price: a.price, sku: a.sku }
    })
  }

  function _componentsForProduct(product) {
    if (!product.Components || product.Components.length === 0) return []

    // if all components are selected then return empty list
    if (product.Components.some((c) => c.selected === false)) {
      return product.Components.map((c) => {
        return { id: c.id, name: c.name, selected: c.selected, sku: c.sku }
      })
    }
    return []
  }

  function makeLineItemProduct(product) {
    //console.log("make line item", product)

    const _comboObservation = (combo) => {
      return combo.selectedItem.name
    }

    let item = {
      id: 0,
      orderId: 0,
      productId: product.id,
      serviceId: 0,
      name: product.name,
      description: product.description,
      quantity: product.quantity,
      price: getProductCost(product),
      size: product.size,
      observation: product.comments,
      sku: product.sku,
      addons: JSON.stringify(_addonsForProduct(product)),
      components: JSON.stringify(_componentsForProduct(product)),
      options: JSON.stringify(_optionsForProduct(product)),
    }
    console.log("make line item", item)

    if (product.chosenSize) {
      item.size = product.chosenSize
      item.price = product.chosenPrice

      console.log("item  chosen size/price", item.size, item.price)
    }

    if (product.type === 'Combo') {
      //item.price = getComboCost(product)
      // getProductCost already gets the right price

      console.log("item combo price", item.price)
      // add the chosen combo products to the observation
      item.observation = [...product.Combos.map((pc) => _comboObservation(pc)), product.comments].join(", ")
    }

    return item
  }


}